import React from 'react'

// Helpers
import formatPrice from '../../helpers/format-price'

const Summary = ({ plan, cost, features, promo, month }) => {
  if (isNaN(cost)) cost = parseFloat(cost)

  return (
    <>
      <div className="main-container">
        <h3 className="text-left mb-1 text-3xl text-blue">Alex {plan}</h3>
        {/* <p><span className="text-xl text-blue">{features[0].type} ${features[0].price}</span></p> */}
        <p className="text-left text-xl mb-0">
          Total a pagar:{' '}
          {/* <span className="text-red line-through">${formatPrice(cost)}</span> $ */}
          <span className="text-green-500 font-semibold">
            ${formatPrice(cost)}
          </span>{' '}
          /anual
        </p>
      </div>
    </>
  )
}

export default Summary
