import React, { useEffect } from 'react'
import { logout } from '../../services/auth'
import { navigate } from 'gatsby'

const Logout = () => {
    useEffect(() => {
        logout(() => { navigate("/") })
    })
    return (
        <>
            <h1>Bye!</h1>
        </>
    )
}
export default Logout