import { EventEmitter } from '../services/events'

// Services
import functions from '../services/functions'

const Braintree = () => {
  /**
   *
   * @param {Object} request
   * @param {String} request.paymentMethodId - Braintree's payment nonce
   * @param {Number} request.cost - Amount to be charged
   * @param {String} request.userId - User's ID
   * @param {Object} request.selected - User's selected plan
   * @param {String} request.type - Payment method
   * @param {String} request.refCode - User referral code
   *
   * @returns {String} Purchase confirmation PDF download link
   */
  const submitTx = async ({
    paymentMethodId,
    cost,
    selected,
    userId,
    type,
    refCode,
  }) => {
    try {
      EventEmitter.dispatch('toggleLoading', { show: true })
      const { data } = await functions.post(
        'submit',
        { paymentMethodId, selected, cost, userId, type, refCode },
        { json: true }
      )
      EventEmitter.dispatch('toggleLoading', { show: false })
      if (data.success) {
        type === 'signup'
          ? EventEmitter.dispatch('toggleModal', {
              show: true,
              success: true,
              title: 'Póliza contratada',
              plan: selected.name,
              message: '¡Felicidades! Has contratado tu póliza',
              navigateTo: '/users/upload',
            })
          : EventEmitter.dispatch('toggleModal', {
              show: true,
              success: true,
              title: 'Cobertura mejorada',
              plan: selected.name,
              message: 'Has mejorado tu cobertura a',
              navigateTo: '/users/home',
            })
        return data
      } else {
        type === 'signup'
          ? EventEmitter.dispatch('toggleModal', {
              show: true,
              success: false,
              title: '¡Lo sentimos!',
              message: 'En este momento no podemos contratar tu póliza',
            })
          : EventEmitter.dispatch('toggleModal', {
              show: true,
              success: false,
              title: '¡Lo sentimos!',
              message: 'En este momento no podemos mejorar tu cobertura',
            })
      }
    } catch (e) {
      console.log(e)
      EventEmitter.dispatch('toggleLoading', { show: false })
      type === 'signup'
        ? EventEmitter.dispatch('toggleModal', {
            show: true,
            success: false,
            title: '¡Lo sentimos!',
            message: 'En este momento no podemos contratar tu póliza',
          })
        : EventEmitter.dispatch('toggleModal', {
            show: true,
            success: false,
            title: '¡Lo sentimos!',
            message: 'En este momento no podemos mejorar tu cobertura',
          })
    }
  }
  const getUserPaymentMethods = async email => {
    EventEmitter.dispatch('toggleLoading', { show: true })
    try {
      const response = await functions.get(
        'getmethods',
        { params: { email } },
        { json: true }
      )
      EventEmitter.dispatch('toggleLoading', { show: false })
      if (response.data.success) {
        return response.data.data
      } else {
        throw new Error('No payment methods available')
      }
    } catch (e) {
      EventEmitter.dispatch('toggleLoading', { show: false })
    }
  }
  return { submitTx, getUserPaymentMethods }
}

export default Braintree()
