import React from 'react'
import { calculateAge } from '../../helpers/calculate-age'
import styles from './coverage-certificate.styles'
import { Page, Text, View, Document, Image, Link } from '@react-pdf/renderer'

const CoverageCertificate = ({ user, selected, quote, beneficiaries }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View style={styles.headerLine} />
          <Image
            style={styles.headerImage}
            src="https://res.cloudinary.com/avilabs/image/upload/v1620200975/logo-red_tifkzi.png"
            alt="Redbridge Logo"
          />
          <View style={styles.headerLine} />
        </View>
        <View>
          <Text style={[styles.title, styles.centered]}>
            CERTIFICADO DE COBERTURA
          </Text>
          <Text style={[styles.subTitle, styles.centered]}>
            Accidentes Personales
          </Text>
          <Image
            style={[styles.alexLogo, styles.centered]}
            src="https://res.cloudinary.com/avilabs/image/upload/v1616848963/alex-logo-short_gqfwtm.png"
            alt="Alex Logo"
          />
          <Text style={styles.justified}>
            Este Certificado de Cobertura es emitido con base en la información
            proporcionada por el Asegurado en la Aplicación Electrónica Alex en
            Línea, sujeto en todos los aspectos a las definiciones, términos y
            condiciones, limitaciones y exclusiones del Plan y sus Anexos. Este
            Certificado junto a las Disposiciones Generales y condiciones de la
            Póliza de Accidentes Personales y Anexos incluidos conforman el
            contrato único entre las partes, sujeto al pago de la prima por
            anticipado. Este Plan solo permite un Asegurado por Certificado.
          </Text>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View
                style={[
                  styles.tableCell,
                  styles.fontBold,
                  styles.textRight,
                  styles.col20,
                ]}
              >
                <Text>ASEGURADO</Text>
              </View>
              <Text
                style={[
                  styles.tableCell,
                  styles.tableInfo,
                  styles.col80,
                  styles.tableLastElement,
                ]}
              >
                {user.name} {user.lastName}
              </Text>
            </View>
            <View style={styles.tableRow}>
              <View
                style={[
                  styles.tableCell,
                  styles.fontBold,
                  styles.textRight,
                  styles.col20,
                ]}
              >
                <Text>NÚMERO DE PÓLIZA</Text>
              </View>
              <Text style={[styles.tableCell, styles.tableInfo, styles.col30]}>
                {user.policyNumber}
              </Text>
              <View
                style={[
                  styles.tableCell,
                  styles.fontBold,
                  styles.textRight,
                  styles.col20,
                ]}
              >
                <Text>PLAN SELECCIONADO</Text>
              </View>
              <Text
                style={[
                  styles.tableCell,
                  styles.tableInfo,
                  styles.capitalized,
                  styles.col30,
                  styles.tableLastElement,
                ]}
              >
                {selected.plan}
              </Text>
            </View>
            <View style={styles.tableRow}>
              <View
                style={[
                  styles.tableCell,
                  styles.fontBold,
                  styles.textRight,
                  styles.col20,
                ]}
              >
                <Text>FECHA DE EFECTIVIDAD</Text>
              </View>
              <Text style={[styles.tableCell, styles.tableInfo, styles.col30]}>
                {new Date(
                  user.policyStartDate.seconds * 1000
                ).toLocaleDateString('en-US')}
              </Text>
              <View
                style={[
                  styles.tableCell,
                  styles.fontBold,
                  styles.textRight,
                  styles.col20,
                ]}
              >
                <Text>PERÍODO</Text>
              </View>
              <Text
                style={[
                  styles.tableCell,
                  styles.tableInfo,
                  styles.col30,
                  styles.tableLastElement,
                ]}
              >
                {new Date(
                  user.policyStartDate.seconds * 1000
                ).toLocaleDateString('en-US')}
                {' - '}
                {new Date(user.policyEndDate.seconds * 1000).toLocaleDateString(
                  'en-US'
                )}
              </Text>
            </View>
            <View style={styles.tableRow}>
              <View
                style={[
                  styles.tableCell,
                  styles.fontBold,
                  styles.textRight,
                  styles.col20,
                ]}
              >
                <Text>FECHA DE NACIMIENTO</Text>
              </View>
              <Text style={[styles.tableCell, styles.tableInfo, styles.col30]}>
                {new Date(quote.dob.seconds * 1000).toLocaleDateString('en-US')}
              </Text>
              <View
                style={[
                  styles.tableCell,
                  styles.fontBold,
                  styles.textRight,
                  styles.col20,
                ]}
              >
                <Text>MODO DE PAGO</Text>
              </View>
              <Text
                style={[
                  styles.tableCell,
                  styles.tableInfo,
                  styles.col30,
                  styles.tableLastElement,
                ]}
              >
                Anual
              </Text>
            </View>
            <View style={styles.tableRow}>
              <View
                style={[
                  styles.tableCell,
                  styles.fontBold,
                  styles.textRight,
                  styles.col20,
                ]}
              >
                <Text>EDAD DE EMISIÓN</Text>
              </View>
              <Text style={[styles.tableCell, styles.tableInfo, styles.col30]}>
                {calculateAge({
                  dob: quote.dob,
                  isFirebaseDate: true,
                })}
              </Text>
              <View
                style={[
                  styles.tableCell,
                  styles.fontBold,
                  styles.textRight,
                  styles.col20,
                ]}
              >
                <Text>SUMA ASEGURADA</Text>
              </View>
              <Text
                style={[
                  styles.tableCell,
                  styles.tableInfo,
                  styles.col30,
                  styles.tableLastElement,
                ]}
              >
                ${selected.features[0].price}
              </Text>
            </View>
            <View style={[styles.tableRow, styles.tableLastElement]}>
              <View
                style={[
                  styles.tableCell,
                  styles.fontBold,
                  styles.textRight,
                  styles.col20,
                ]}
              >
                <Text>PAIS DE RESIDENCIA</Text>
              </View>
              <Text style={[styles.tableCell, styles.tableInfo, styles.col30]}>
                {quote.residence}
              </Text>
              <View
                style={[
                  styles.tableCell,
                  styles.fontBold,
                  styles.textRight,
                  styles.col20,
                ]}
              >
                <Text>PRIMA ANUAL</Text>
              </View>
              <Text
                style={[
                  styles.tableCell,
                  styles.tableInfo,
                  styles.col30,
                  styles.tableLastElement,
                ]}
              >
                $
                {parseFloat(
                  selected.price.replace(',', '.') * 12
                ).toFixed(2)}
              </Text>
            </View>
          </View>
          <Text style={[styles.sectionTitle, styles.fontBold]}>
            ANEXOS INCLUIDOS
          </Text>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View
                style={[
                  styles.tableCell,
                  styles.centered,
                  styles.fontBold,
                  styles.col20,
                ]}
              >
                <Text>BENEFICIOS</Text>
              </View>
              <View
                style={[
                  styles.tableCell,
                  styles.centered,
                  styles.fontBold,
                  styles.col15,
                ]}
              >
                <Text>LÍMITE MÁXIMO</Text>
              </View>
              <View
                style={[
                  styles.tableCell,
                  styles.fontBold,
                  styles.col65,
                  styles.tableLastElement,
                ]}
              >
                <Text>CONDICIONES PARTICULARES & REQUERIMIENTOS</Text>
              </View>
            </View>
            <View style={[styles.tableRow, styles.benefitsTableRow]}>
              <View
                style={[
                  styles.tableCell,
                  styles.centered,
                  styles.fontBold,
                  styles.col20,
                ]}
              >
                <Text>SEGURO DE VIDA BÁSICO</Text>
              </View>
              <View
                style={[
                  styles.tableCell,
                  styles.centered,
                  styles.tableInfo,
                  styles.col15,
                ]}
              >
                <Text>${selected.features[5].price}</Text>
              </View>
              <View
                style={[
                  styles.tableCell,
                  styles.tableInfo,
                  styles.col65,
                  styles.tableLastElement,
                ]}
              >
                <Text>
                  Período de Espera 90 días, comprende la enfermedad Covid-19.
                </Text>
              </View>
            </View>
            <View style={[styles.tableRow, styles.benefitsTableRow]}>
              <View
                style={[
                  styles.tableCell,
                  styles.centered,
                  styles.fontBold,
                  styles.col20,
                ]}
              >
                <Text>ASISTENCIA AL VIAJERO Y REPATRACIÓN</Text>
              </View>
              <View
                style={[
                  styles.tableCell,
                  styles.centered,
                  styles.tableInfo,
                  styles.col15,
                ]}
              >
                <Text>${selected.features[6].price}</Text>
              </View>
              <View
                style={[
                  styles.tableCell,
                  styles.tableInfo,
                  styles.col65,
                  styles.tableLastElement,
                ]}
              >
                <Text>
                  Período de Espera 24 horas. Requiere notificación a y
                  pre-certificación por
                </Text>
                <Text>
                  Redbridge dentro de las primeras 24 horas de la ocurrencia.
                </Text>
              </View>
            </View>
            <View
              style={[
                styles.tableRow,
                styles.benefitsTableRow,
                styles.tableLastElement,
              ]}
            >
              <View
                style={[
                  styles.tableCell,
                  styles.centered,
                  styles.fontBold,
                  styles.col20,
                ]}
              >
                <Text>ASISTENCIA FUNERAL</Text>
              </View>
              <View
                style={[
                  styles.tableCell,
                  styles.tableInfo,
                  styles.centered,
                  styles.col15,
                ]}
              >
                <Text>${selected.features[7].price}</Text>
              </View>
              <View
                style={[
                  styles.tableCell,
                  styles.tableInfo,
                  styles.col65,
                  styles.tableLastElement,
                ]}
              >
                <Text>
                  Período de Espera 90 días. Requiere notificación a y
                  pre-certificación por
                </Text>
                <Text>
                  Redbridge dentro de las primeras 48 horas de la ocurrencia
                </Text>
              </View>
            </View>
          </View>
          <Text style={[styles.sectionTitle, styles.fontBold]}>
            BENEFICIARIOS DESIGNADOS
          </Text>
          <View style={[styles.table, styles.beneficiariesTable]}>
            <View style={styles.tableRow}>
              <View
                style={[
                  styles.tableCell,
                  styles.fontBold,
                  styles.centered,
                  styles.col35,
                ]}
              >
                <Text>NOMBRE COMPLETO</Text>
              </View>
              <View
                style={[
                  styles.tableCell,
                  styles.fontBold,
                  styles.centered,
                  styles.col25,
                ]}
              >
                <Text>FECHA DE NACIMIENTO</Text>
              </View>
              <View
                style={[
                  styles.tableCell,
                  styles.fontBold,
                  styles.centered,
                  styles.col25,
                ]}
              >
                <Text>RELACIÓN</Text>
              </View>
              <View
                style={[
                  styles.tableCell,
                  styles.fontBold,
                  styles.centered,
                  styles.col15,
                  styles.tableLastElement,
                ]}
              >
                <Text>PORCIENTO (%)</Text>
              </View>
            </View>
            {beneficiaries.map((beneficiary, index) => (
              <View
                key={index}
                style={[
                  styles.tableRow,
                  index === 3 && styles.tableLastElement,
                ]}
              >
                <Text
                  style={[
                    styles.tableCell,
                    styles.tableInfo,
                    styles.centered,
                    styles.capitalized,
                    styles.col35,
                  ]}
                >
                  {beneficiary.name} {beneficiary.lastName}
                </Text>
                <Text
                  style={[
                    styles.tableCell,
                    styles.tableInfo,
                    styles.centered,
                    styles.col25,
                  ]}
                >
                  {new Date(beneficiary.dob.seconds * 1000).toLocaleDateString(
                    'en-US'
                  )}
                </Text>
                <Text
                  style={[
                    styles.tableCell,
                    styles.tableInfo,
                    styles.centered,
                    styles.capitalized,
                    styles.col25,
                  ]}
                >
                  {beneficiary.relationship}
                </Text>
                <Text
                  style={[
                    styles.tableCell,
                    styles.tableInfo,
                    styles.centered,
                    styles.col15,
                    styles.tableLastElement,
                  ]}
                >
                  {beneficiary.benefit}
                </Text>
              </View>
            ))}
            {[...new Array(4 - beneficiaries.length)].map((_, index) => (
              <View
                key={index}
                style={[
                  styles.tableRow,
                  index === 4 - beneficiaries.length - 1 &&
                    styles.tableLastElement,
                ]}
              >
                <Text style={[styles.tableCell, styles.col35]}>&nbsp;</Text>
                <Text style={[styles.tableCell, styles.col25]}>&nbsp;</Text>
                <Text style={[styles.tableCell, styles.col25]}>&nbsp;</Text>
                <Text
                  style={[
                    styles.tableCell,
                    styles.tableInfo,
                    styles.col15,
                    styles.tableLastElement,
                  ]}
                >
                  &nbsp;
                </Text>
              </View>
            ))}
          </View>
          <Text style={styles.caption}>*Formato de Fechas: MM/DD/AAAA</Text>
          <Text style={styles.justified}>
            En ausencia de una designación de beneficiarios, o por fallecimiento
            de alguno de los beneficiarios designados, el beneficio por muerte
            natural y/o muerte accidental será pagado en partes iguales a los
            herederos legales sobrevivientes o al patrimonio del asegurado
            fallecido mediante presentación de documentos legales satisfactorios
            para la Compañía.
          </Text>
          <Text style={styles.justified}>
            Este Certificado ha sido APROBADO, EMITIDO Y REFRENDADO
            electrónicamente en su Fecha de Efectividad.
          </Text>
        </View>
        <View style={styles.space} />
        <View style={styles.footer}>
          <View>
            <Text style={styles.fontBold}>
              NOTIFICACIÓN Y PRE-CERTIFICACIÓN (24/7)
            </Text>
            <Text>
              <Text style={styles.fontBold}>Teléfonos: </Text>
              +1 (786) 345-1769 | +1 (877) 244-9167 | +58-212-335-7469 |
              +511-706-8442
            </Text>
            <Text>
              <Text style={styles.fontBold}>Email: </Text>
              <Link style={styles.link} src="mailto:service@redbridge.cc">
                service@redbridge.cc
              </Link>{' '}
              |<Text style={styles.fontBold}> WhatsApp: </Text> +1-786-653-3717
              (Solo para Emergencias)
            </Text>
            <Text>
              <Text style={styles.fontBold}>
                Envío de documentos de reclamaciones:{' '}
              </Text>
              <Link style={styles.link} src="mailto:riclclaims@redbridge.cc">
                riclclaims@redbridge.cc
              </Link>
            </Text>
            <Text>
              Redbridge | P.O. Box 144490 – Coral Gables, FL 33114 EE.UU.
            </Text>
          </View>
          <View>
            <Text style={[styles.fontBold, styles.address]}>
              Redbridge Insurance Company, Ltd.
            </Text>
            <Text style={styles.address}>The Loft -Suite 205-F,</Text>
            <Text style={styles.address}>
              Limegrove Lifestyle Centre, Holetown
            </Text>
            <Text style={styles.address}>St. James Barbados</Text>
          </View>
        </View>
      </Page>
    </Document>
  )
}

export default CoverageCertificate
