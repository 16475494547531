import React from 'react'

// Libs
import classnames from 'classnames'

// Styles
import modalStyles from '../../../components/modals/modal.module.css'

const BeneficiaryDeletionModal = ({ beneficiary, onCancel, onConfirm }) => (
  <>
    <div
      className={classnames(
        modalStyles.modalContainer,
        modalStyles.show,
        'p-8'
      )}
    >
      <div className="grid grid-cols-12 modal-body">
        <div className="col-span-12 mx-auto">
          <p>¿Estás seguro que deseas eliminar a</p>
        </div>
        <div className="col-span-12 mx-auto">
          <p className="text-green-500">{`${beneficiary.name} ${beneficiary.lastName}`}</p>
        </div>
        <div className="col-span-12 mx-auto">
          <p>como beneficiario?</p>
        </div>
        <div className="col-span-12 flex-col md:flex-row md:w-3/4 md:mx-auto flex justify-around">
          <button
            className="default-button py-3 px-6 rounded"
            onClick={onCancel}
          >
            Cancelar
          </button>
          <button
            className="outlined-default-button mt-4 md:mt-0 py-3 px-6 rounded"
            onClick={onConfirm}
          >
            Eliminar
          </button>
        </div>
      </div>
    </div>
    <div className={classnames(modalStyles.modalBg, modalStyles.show)} />
  </>
)

export default BeneficiaryDeletionModal
