import React, { useState } from 'react'
import { navigate } from 'gatsby'

// Libs
import { PDFExport } from '@progress/kendo-react-pdf'

/**
 * Handles download of user PDF
 * @param {Object} props
 * @param {Object} props.location - Navigation params  
 */
const UserPDF = ({ location }) => {

  const [file, setPDFile] = useState(null)
  const [wasDownloaded, setDownloadState] = useState(false)

  const downloadPdf = () => {
    setDownloadState(true)
    setTimeout(() => {
      file.save()
      navigate("users/home")
    }, 500)
  }

  if (file && !wasDownloaded) {
    downloadPdf()
  }

  return (
    <PDFExport paperSize={'Letter'}
      fileName="poliza-pedro.pdf"
      title=""
      subject=""
      keywords=""
      ref={(r) => { setPDFile(r) }}>
      <div style={{
        height: 792,
        width: 612,
        padding: 'none',
        backgroundColor: 'white',
        boxShadow: '5px 5px 5px black',
        margin: 'auto',
        overflowX: 'hidden',
        overflowY: 'hidden'
      }}>
        <h1>¡Hola! Soy Alex.</h1>
      </div>
    </PDFExport>
  )
}

export default UserPDF