import React, { useEffect, useState } from 'react'
import { Router } from '@reach/router'
import { navigate } from 'gatsby'

// Components
import SEO from '../../components/seo'
import Layout from '../../components/layout/layout'
import PrivateRoute from '../../components/layout/private-route'
import UserHome from '../../components/users/home'
import Checkout from '../../components/checkout/main'
import Referrals from '../../components/users/referrals'
import AddBeneficiary from '../../components/users/add-beneficiary'
import EditBeneficiary from '../../components/users/edit-beneficiary'
import UpgradeCoverage from '../../components/users/upgrade-coverage'
import MyProfile from '../../components/users/my-profile'
import Logout from '../../components/users/logout'
import WithdrawMethod from '../../components/users/withdraw'
import SelectBankAccount from '../../components/users/select-bank-account'
import AddBankAccount from '../../components/users/add-bank-account'
import ConfirmWithdraw from '../../components/users/confirm-withdraw'
import ConfirmUpgrade from '../../components/users/confirm-upgrade'
import UploadDocs from '../../components/users/upload-docs'
import UserPDF from '../../components/users/pdf'
import UnverifiedUser from '../../components/users/unverified-user'

// Services
import { checkUserVerification, getLocalUser } from '../../services/auth'
import { EventEmitter } from '../../services/events'

// Pages
const UserHomePage = () => {
  const [loading, setLoading] = useState(false)
  const [isUserVerified, setIsUserVerified] = useState(false)

  useEffect(() => {
    const fetchUserVerification = async () => {
      setLoading(true)
      EventEmitter.dispatch('toggleLoading', { show: true })
      setIsUserVerified(await checkUserVerification())
      EventEmitter.dispatch('toggleLoading', { show: false })
      setLoading(false)
    }
    fetchUserVerification()
  }, [])

  const { userId } = getLocalUser()

  if (!userId) {
    if (typeof window !== 'undefined') {
      navigate('/auth/login')
    }
  }

  return (
    <Layout auth={true}>
      <SEO title="Mi cuenta" />
      {!loading && (
        <>
          <Router>
            {isUserVerified && (
              <>
                <PrivateRoute path="users/home" component={UserHome} />
                <PrivateRoute path="users/upload" component={UploadDocs} />
                <PrivateRoute path="users/pdf" component={UserPDF} />
                <PrivateRoute path="users/checkout" component={Checkout} />
                <PrivateRoute path="users/referrals" component={Referrals} />
                <PrivateRoute
                  path="users/upgrade-coverage"
                  component={UpgradeCoverage}
                />
                <PrivateRoute
                  path="users/add-beneficiary"
                  component={AddBeneficiary}
                />
                <PrivateRoute
                  path="users/edit-beneficiary/:beneficiaryId"
                  component={EditBeneficiary}
                />
                <PrivateRoute
                  path="users/new-beneficiary"
                  component={() => {
                    navigate('/users/add-beneficiary', { replace: true })
                    return null
                  }}
                />
                <PrivateRoute
                  path="users/withdraw"
                  component={WithdrawMethod}
                />
                <PrivateRoute
                  path="users/select-bank-account"
                  component={SelectBankAccount}
                />
                <PrivateRoute
                  path="users/add-bank-account"
                  component={AddBankAccount}
                />
                <PrivateRoute
                  path="users/confirm-withdraw"
                  component={ConfirmWithdraw}
                />
                <PrivateRoute
                  path="users/confirm-upgrade"
                  component={ConfirmUpgrade}
                />
                <PrivateRoute path="users/my-profile" component={MyProfile} />
              </>
            )}
            <PrivateRoute path="users/logout" component={Logout} />
          </Router>
          {userId && !isUserVerified && <UnverifiedUser />}
        </>
      )}
    </Layout>
  )
}

export default UserHomePage
