import formatNum from "./format-number"

export default (quote) => {
  let data = []
  for (let prop in quote) {
    if (quote.hasOwnProperty(prop)) {
      switch (prop) {
        case "standard":
        case "standardNicotine":
          if (quote.standard || quote.standardNicotine)
            data.push({
              name: "Standard",
              plan: prop,
              price: formatNum(quote.standard) || formatNum(quote.standardNicotine),
              features: quote.standardFeatures && Object.keys(quote.standardFeatures).length ?
                Object.keys(quote.standardFeatures).map(key => buildFeatures(key, quote.standardFeatures))
                  .filter(Boolean).sort((a, b) => a.index - b.index) :
                []
            })
          break;
        case "plus":
        case "plusNicotine":
          if (quote.plus || quote.plusNicotine)
            data.push({
              name: "Prime",
              plan: prop,
              price: formatNum(quote.plus) || formatNum(quote.plusNicotine),
              features: quote.plusFeatures && Object.keys(quote.plusFeatures).length ?
                Object.keys(quote.plusFeatures).map(key => buildFeatures(key, quote.plusFeatures))
                  .filter(Boolean).sort((a, b) => a.index - b.index) :
                []
            })
          break;
        case "full":
        case "fullNicotine":
          if (quote.full || quote.fullNicotine)
            data.push({
              name: "Alex Full",
              plan: prop,
              price: formatNum(quote.full) || formatNum(quote.fullNicotine),
              features: quote.fullFeatures && Object.keys(quote.fullFeatures).length ?
                Object.keys(quote.fullFeatures).map(key => buildFeatures(key, quote.fullFeatures))
                  .filter(Boolean).sort((a, b) => a.index - b.index) :
                []
            })
          break;
        default:
          break;
      }
    }
  }
  return data
}

function buildFeatures(key, obj) {

  switch (key) {
    case "accident":
      return { type: "Maximo beneficio", price: formatNum(obj[key]), active: !!obj[key], symbol: "$", index: 0 }

    case "accidental_percent":
      return { type: "Muerte accidental", price: obj[key], active: !!obj[key], symbol: "%", index: 1 }

    case "total_disability":
      return { type: "Invalidez total", price: obj[key], active: !!obj[key], symbol: "%", index: 2 }

    case "partial_disability":
      return { type: "Invalidez parcial", price: obj[key], active: !!obj[key], symbol: "%", index: 3 }

    case "dismemberment":
      return { type: "Desmembramiento", price: obj[key], active: !!obj[key], symbol: "%", index: 4 }

    case "natural":
      return { type: "Muerte natural", price: formatNum(obj[key]), active: !!obj[key], symbol: "$", index: 5 }

    case "travel":
      return { type: "Asistencia en viaje", price: formatNum(obj[key]), active: !!obj[key], symbol: "$", index: 6 }

    case "funerary":
      return { type: "Asistencia funeraria", price: formatNum(obj[key]), active: !!obj[key], symbol: "$", index: 7 }

    default:
      break;
  }
}