import React, { useState, useEffect } from 'react'

// Services
import { getLocalUser } from "../../services/auth"
import Braintree from "../../services/braintree"

// Components
import UserProfileData from './user-profile-data'
import UserPaymentMethods from './user-payments'
import UserBilling from './billing'
import Title from "../global/title"

const MyProfile = () => {
  const [user, setUser] = useState(null)
  const [bills, setBills] = useState([])
  const [methods, setPaymentMethods] = useState(false)

  useEffect(() => {
    if (!user) {
      setUser(() => getLocalUser())
    } else {
      setBills(() => {
        if (user && user.statements)
          return user.statements.map(s => {
            return {
              date: s.creationDate ? new Date(s.creationDate.seconds * 1000) : '',
              cost: s.cost,
              plan: "Alex " + s.selected.name
            }
          })
        return []
      })
    }
    if (!methods && user) {
      async function getPaymentMethods() {
        const data = await Braintree.getUserPaymentMethods(user.email)
        setPaymentMethods(data)
      }
      getPaymentMethods()
    }
  }, [user, methods])
  return (
    user ?
      <>
        <div className='grid grid-cols-12'>
          <Title _private main={"Mi Perfil"} />
          <div className="col-start-2 col-span-10 lg:col-span-12">
            <UserProfileData profile={user} />
          </div>
          <div className="col-start-2 col-span-10 lg:col-span-12">
            <UserPaymentMethods cards={methods} />
          </div>
          <div className="col-start-2 col-span-10 lg:col-span-12">
            <UserBilling bills={bills} />
          </div>
        </div>
      </>
      :
      <>
        <div className='grid grid-cols-12'>
          <div className="col-start-2 col-span-12 mt-10 lg:mt-40 lg:col-start-1">
            <h1 className="font-normal text-4xl">Cargando...</h1>
          </div>
        </div>
      </>
  )
}

export default MyProfile
