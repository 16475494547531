import React from "react"

// Components
import AddBankAccountForm from "../forms/add-bank-account-form"

const NewBankAccount = ({ location }) => {
  const { state = {} } = location
  const { userId, reward } = state
  return (
    <>
      <div className="grid grid-cols-12 mt-12 lg:mt-40">
        <div className="col-start-2 lg:col-start-1 col-span-10 lg:col-span-8">
          <h1 className="mb-4">Agregar una cuenta bancaria</h1>
          <p className="text-gray-700 text-xl">
            Ingrese los datos de la cuenta bancaria
          </p>
          <AddBankAccountForm reward={reward} userId={userId} />
        </div>
      </div>
    </>
  )
}

export default NewBankAccount
