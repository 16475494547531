import React from 'react'

const UserPaymentMethods = ({ cards }) => {
  return (
    <>
      {cards && cards.length &&
        <div className="grid grid-cols-12 gap-2 mb-10">
          <div className='col-span-10'>
            <p className='mb-4 text-xl bg-gray rounded p-1 font-bold opacity-75'>Métodos de pago</p>
          </div>
          {cards.map((c, i) =>
            c.priority === 0 ?
              <div key={i} className="col-span-12">
                <div className="grid grid-cols-12">
                  <div className='col-span-6 lg:col-span-4'>
                    <p className='mb-3 text-gray-600 text-xs'>Principal</p>
                    <p className='mb-3 text-xl'>{c.brandCode} ****{c.last4}</p>
                  </div>
                  <div className='col-span-6 my-auto'>
                    <button className='bg-black text-white text-xs px-2 mr-1 rounded'>Editar</button>
                    <button className='bg-red text-white text-xs px-2 rounded'>Eliminar</button>
                  </div>
                </div>
              </div>
              :
              <div key={i} className="col-span-12">
                <div className="grid grid-cols-12">
                  <div className='col-span-6 lg:col-span-4'>
                    <p className='mb-3 text-gray-600 text-xs'>Secundario</p>
                    <p className='mb-3 text-xl'>{c.brandCode} ****{c.last4}</p>
                  </div>
                  <div className='col-span-6 my-auto'>
                    <button className='bg-black text-white text-xs px-2 mr-1 rounded'>Editar</button>
                    <button className='bg-red text-white text-xs px-2 rounded'>Eliminar</button>
                  </div>
                </div>
              </div>
          )}
          {/* <div className='col-span-12'>
        <button className="bg-black text-white hover:bg-gray hover:text-black rounded py-2 px-4" type="button">+ Agregar método de pago</button>
      </div> */}
        </div>
      }
    </>
  )
}

export default UserPaymentMethods