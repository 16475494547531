import React from 'react'

const TosCheckout = () => (
         <section className="grid grid-cols-12 text-left">
             <div className="col-span-12">
                Esta Política de Cancelación y Devolución es incorporada y forma parte de los términos y condiciones del plan ALEX.<br/><br/>

                <h4>CANCELACIÓN Y DEVOLUCIÓN</h4> 
                Usted tiene un período de catorce (14) días naturales a partir de la Fecha de Efectividad de esta Póliza, para cancelar la misma. Para solicitar cancelación de la Póliza, escribanos a soporte@alexseguro.com o acceda a “Mi Póliza” a través de www.alexseguro.com. Si su solicitud de cancelación es recibida dentro del plazo aquí especificado, nosotros procederemos  a invalidar la Póliza y devolver el pago recibido menos la cantidad de USD $20.00 mediante el mismo método de pago utilizado en el momento de su compra.<br/><br/>

                <b>UNA SOLICITUD DE CANCELACIÓN RECIBIDA DESPUÉS DEL PERIODO DE CATORCE (14) DIAS NATURALES SE CONSIDERARÁ UNA SOLICITUD DE NO RENOVACIÓN. LA COBERTURA DE ESTA PÓLIZA SE MANTENDRÁ EN VIGOR HASTA SU FECHA DE EXPIRACIÓN Y NO SE RENOVARÁ.</b><br/><br/>

                La modalidad de pago de la prima de esta Póliza es anual por cobro recurrente y deberá ser pagada en dólares de EE.UU. antes de o en la fecha de vencimiento. El impago de la prima resultará en la cancelación automática de la Póliza en su última fecha de vencimiento.<br/><br/>

                <h4>TARJETA DE CRÉDITO</h4>  
                La tramitación de una tarjeta de crédito requiere la adquisición de la información pertinente, incluyendo el número de la tarjeta de crédito, fecha de expiración, nombre del titular y el código CVV2, según aparece en la tarjeta de crédito.  Información adicional podría incluir; pero no taxativamente la dirección de facturación de la tarjeta de crédito, un correo electrónico válido y número de teléfono del comprador, y la exención del impuesto de venta, cuando aplique.<br/><br/>

                <h4>TÉRMINOS Y CONDICIONES DE UTILIZACIÓN</h4>

                <b>REDBRIDGE</b> junto con sus subsidiarias y afiliadas, (“Redbridge”) provee la información y servicios en su Página Web  (“Sitio”) bajo los siguientes términos y condiciones. Al acceder o utilizar este Sitio, usted (“Usuario”) acepta todos los términos y condiciones.<br/><br/>
                <b>LEYES Y REGULACIONES:</b> El acceso y uso de este Sitio está sujeto a todas leyes y regulaciones aplicables. El Usuario acuerda no utilizar este Sitio de forma alguna que viole estas reglas y regulaciones.<br/><br/>

                <b>DERECHOS DE AUTOR Y MARCA REGISTRADA:</b> La información disponible en o a través de este Sitio es propiedad de Redbridge, o sus licenciantes, y está protegida por las leyes de los Derechos de Autor y Marca Registrada. Los Usuarios no pueden modificar, copiar, distribuir, transmitir, publicar, vender, licenciar, crear escritos derivados de, o de ninguna otra forma utilizar cualquier información disponible en o a través de este Sitio para uso comercial. Los Usuarios no pueden utilizar la marca registrada, logos y marcas de servicios (“Marcas”) incluyendo, taxativamente el uso de “enlaces clave” (hot links) o “portales registrados” (meta tags) en otras páginas o sitios en la Página Web Global sin el previo consentimiento escrito de Redbridge.<br/><br/>

                <b>INOBSERVANCIAS:</b> El Usuario acuerda no modificar, mover, añadir a, eliminar o de otra manera infringir la información comprendida en este Sitio. El Usuario también acuerda no descompilar, revertir ingeniería, desarmar, o ilegalmente usar o reproducir cualquier programa u otra propiedad material comprendida en este Sitio.<br/><br/>

                <b>ENLACES A OTROS SITIOS:</b> Este Sitio puede incluir enlaces a otros sitios-web que no están bajo el control de Redbridge. Esto es hecho por conveniencia y Redbridge no aprueba ni asume obligación o responsabilidad civil por el contenido de estos sitios.<br/><br/>

                <b>ENLACES DE OTROS SITIOS:</b> Redbridge prohíbe los enlaces no autorizados a este Sitio y la aplicación de cualquier información aquí comprendida. Redbridge se reserva el derecho de deshabilitar cualquier enlace o aplicación no autorizada. Redbridge no aprueba ni se responsabiliza por la información comprendida en cualquier otro sitio-web que pueda tener enlace con este Sitio.<br/><br/>

                <b>INFORAMACION A TERCERAS PARTES:</b> Alguna de la información en este Sitio puede ser facilitada por terceras partes. Mientras que Redbridge realiza todo esfuerzo comercialmente razonable para atestar la exactitud de la información en este Sitio, esto no garantiza la exactitud o integridad de cualquier información.<br/><br/>

                <b>NO GARANTIAS:</b> La información y documentos en este Sitio son provistos sin garantía de ningún tipo, tanto expresa o implícita, incluyendo, sin limitación, garantías de comercialización, aptitud para particular propósito y no-infracción. Redbridge no ofrece garantías ni representaciones de la exactitud o integridad de cualquier información en este Sitio. Redbridge, de vez en cuando, podrá añadir, cambiar, o eliminar sin previo aviso, información y documentos de este Sitio. Redbridge no acepta obligación ni responsabilidad civil por errores y omisiones en este Sitio. Los usuarios acceden y utilizan este Sitio, bajo su responsabilidad.<br/><br/>

                <b>LIMITE DE RESPONSABILIDAD:</b> Bajo ninguna circunstancia Redbridge será responsable por daños sufridos por el Usuario, incluyendo cualquier daño incidental, especifico o daño consecuente, que derive de acceder, utilizar o inhabilitar el uso de este Sitio, o por cualquier infracción de seguridad asociada con la transmisión de información a través de internet, incluso cuando Redbridge haya sido notificado de la posibilidad de dichos daños.<br/><br/>

                <b>PRIVACIDAD:</b> Favor de referirse al Aviso de Privacidad de Redbridge, publicado en este Sitio.<br/><br/>

                <b>SEGURIDAD:</b> La seguridad de la información transmitida a través de internet no es garantizada. Redbridge no es responsable por cualquier intercepción o interrupción de cualquier comunicación a través de internet o por cambios o perdida de datos. El Usuario es responsable por la seguridad de cualquier identificación, clave de seguridad o cualquier otra forma de autenticidad.<br/><br/>

                <b>TRANSMISION DE DATOS PERSONALES:</b> El Usuario reconoce y acepta que al proveer a Redbridge con cualquier información personal a través de este Sitio, el Usuario consiente a la trasmisión de dicha información a través de confines internacionales.<br/><br/>

                <b>ACCESO DE AREAS PROTEGIDAS:</b> El acceso y uso de clave de seguridad en áreas protegidas de este Sitio se encuentra restringidos a usuarios autorizados únicamente. El acceso no-autorizado está prohibido en dichas áreas.<br/><br/>


                <h4>AVISO DE PRIVACIDAD</h4>

                <b>REDBRIDGE</b> obedece y realiza todo esfuerzo comercialmente razonable para proteger su (Usuario) privacidad. Las normas a   continuation rigen la información y práctica de privacidad nuestra en esta Página Web (Sitio).<br/><br/>

                <h4>USO DE INFORMACIÓN</h4> 
                Información confidencial puede ser utilizada para rastrear el número de visitantes a este Sitio. Esta información es únicamente para uso interno y no es compartida con terceras partes. Información personal identificable como es, nombre, dirección y número de identificación solo se obtiene cuando el Usuario la provee voluntariamente y con el único propósito de identificación. Nosotros no divulgaremos su información sin su permiso, excepto con el propósito de cumplir con su solicitud de información, productos o servicios ofrecidos en este Sitio. Nosotros no negociaremos su información sin su consentimiento.<br/><br/>

                <h4>REQUISITO LEGAL</h4> Nosotros podríamos necesitar revelar su información personal confidencial cuando nos sea requerido por ley, como cuando sea necesario cumplir con un mandato gubernamental, procedimientos jurídicos, orden de la corte o cualquier otro proceso legal.<br/><br/>

                <h4>OTRAS PAGINAS WEB</h4>  Este Sitio puede tener conexiones a otros sitios-web con avisos y prácticas de privacidad diferentes. Nosotros no somos responsables por los avisos o prácticas de privacidad de cualquier otro sitio web.<br/><br/>

                <h4>CONFIDENCIALIDAD</h4> Nosotros haremos todo esfuerzo comercialmente razonable para proteger la información confidencial de nuestros Usuarios contra daños o uso no-autorizado. El acceso a su información confidencial es restringido a nuestros empleados, representantes autorizados y otros que necesitan conocer de esta información para proveerle a usted con la información, productos y servicios que usted ha solicitado. Nosotros salvaguardamos los recursos tangibles, electrónicos y técnicos para proteger información confidencial.<br/><br/>

                <h4>MODIFICACIONES</h4> Este Aviso de Privacidad está sujeto a cambios sin previa notificación; pero cualquier cambio será colocado en nuestra página web (Sitio).<br/><br/>
            </div>
            </section>
            )

export default TosCheckout