import React from 'react'
import { pdf } from '@react-pdf/renderer'

import { getLocalUser } from '../services/auth'
import firebase from '../services/firebase'
import Cloudinary from '../services/cloudinary'

import CoverageCertificate from '../components/coverage-certificate/coverage-certificate'

export default async () => {
  const { userId } = getLocalUser()
  if (!userId) {
    return
  }
  const data = await getUserData(userId)

  const policyBlob = await pdf(<CoverageCertificate {...data} />).toBlob()

  await Cloudinary().uploadPolicy(new File([policyBlob], `policy${userId}.pdf`))
}

const getUserData = async userId => {
  const user = (
    await firebase.db
      .collection('users')
      .doc(userId)
      .get()
  ).data()
  const selected = (
    await firebase.db
      .collection('policies')
      .doc(userId)
      .get()
  ).data()
  const quote = (
    await firebase.db
      .collection('user-quotes')
      .doc(userId)
      .get()
  ).data()
  let beneficiariesQuery = await firebase.db
    .collection('beneficiaries')
    .where('userId', '==', userId)
    .get()
  let beneficiaries = []
  beneficiariesQuery.forEach(snap => {
    beneficiaries.push(snap.data())
  })

  return { user, selected, quote, beneficiaries }
}
