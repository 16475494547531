import { StyleSheet } from '@react-pdf/renderer'
import { Font } from '@react-pdf/renderer'

Font.register({
  family: 'Open Sans',
  fonts: [
    {
      src:
        'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf',
    },
    {
      src:
        'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf',
      fontWeight: 700,
    },
  ],
})
Font.registerHyphenationCallback(word => [word])

export default StyleSheet.create({
  page: {
    width: '100%',
    height: '100vh',
    paddingVertical: '9mm',
    paddingHorizontal: 35,
    fontSize: 9,
    fontFamily: 'Open Sans',
    // color: 'black',
    flexDirection: 'column',
  },
  header: {
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 12,
    flexDirection: 'row',
  },
  headerLine: {
    borderTop: '1px solid black',
    flex: 1,
    height: 16,
  },
  headerImage: {
    position: 'relative',
    width: '45mm',
    marginHorizontal: '4mm',
  },
  centered: {
    textAlign: 'center',
  },
  textRight: {
    textAlign: 'right',
  },
  justified: {
    textAlign: 'justify',
    lineHeight: 1.3,
    marginBottom: 12,
  },
  fontBold: {
    fontWeight: 'bold',
  },
  capitalized: {
    textTransform: 'capitalize',
  },
  title: {
    fontSize: 13,
    fontWeight: 700,
    color: 'dimgray',
    paddingBottom: 4,
  },
  subTitle: {
    fontSize: 13,
    fontWeight: 700,
    color: 'firebrick',
    paddingBottom: 8,
  },
  alexLogo: {
    marginHorizontal: 'auto',
    width: '21mm',
    paddingBottom: 14,
  },
  sectionTitle: {
    paddingBottom: 2,
    borderBottom: '1px solid black',
    marginBottom: 10,
  },
  caption: {
    fontSize: 6,
    marginBottom: 12,
  },
  table: {
    display: 'table',
    borderStyle: 'solid',
    borderWidth: 1,
    marginLeft: -8,
    marginBottom: 12,
  },
  tableRow: {
    flexDirection: 'row',
    borderStyle: 'solid',
    borderBottomWidth: 1,
  },
  tableCell: {
    fontSize: 7,
    paddingVertical: 1,
    paddingHorizontal: 5,
    borderStyle: 'solid',
    borderRightWidth: 1,
    minHeight: 14,
    height: '100%',
    justifyContent: 'center',
  },
  benefitsTableRow: {
    height: 25,
  },
  tableLastElement: {
    borderWidth: 0,
  },
  tableInfo: {
    fontSize: 9,
  },
  col15: {
    width: '15%',
  },
  col20: {
    width: '20%',
  },
  col25: {
    width: '25%',
  },
  col30: {
    width: '30%',
  },
  col35: {
    width: '35%',
  },
  col65: {
    width: '65%',
  },
  col80: {
    width: '80%',
  },
  beneficiariesTable: {
    marginBottom: 8,
  },
  space: {
    flex: 1,
  },
  footer: {
    maxWidth: '100%',
    fontSize: 6,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  link: {
    color: 'dodgerblue',
  },
  address: {
    marginLeft: 'auto',
  },
})
