import React, { useState } from 'react'
import { navigate } from 'gatsby'

// Icons
import { BsTrash } from 'react-icons/bs'
import { RiEdit2Fill } from 'react-icons/ri'

// Components
import BeneficiaryDeletionModal from './delete-beneficiary-modal'
import BeneficiaryDeletionErrorModal from './delete-beneficiary-modal/error-modal'
import BeneficiaryDeletionSuccessModal from './delete-beneficiary-modal/success-modal'

// Services
import firebase from '../../services/firebase'
import { EventEmitter } from '../../services/events'

import uploadPolicy from '../../helpers/upload-policy'

const MyBeneficiariesComponent = ({ beneficiaries, reloadBeneficiaries }) => {
  const [beneficiaryToDelete, setBeneficiaryToDelete] = useState()
  const [showModal, setShowModal] = useState()

  const selectBeneficiary = beneficiary => {
    setBeneficiaryToDelete(beneficiary)
    setShowModal('confirmation')
  }

  const deleteBeneficiary = async () => {
    if (beneficiaries.length <= 1) {
      setShowModal('error')
      return
    }
    setShowModal()
    EventEmitter.dispatch('toggleLoading', { show: true })
    const benefit = beneficiaryToDelete.benefit
    const remainingBeneficiaries = beneficiaries.filter(
      b => b.uid !== beneficiaryToDelete.uid
    )
    await firebase.db
      .collection('beneficiaries')
      .doc(beneficiaryToDelete.uid)
      .delete()
    await Promise.all(
      remainingBeneficiaries.map(beneficiary =>
        firebase.db
          .collection('beneficiaries')
          .doc(beneficiary.uid)
          .update({
            benefit:
              beneficiary.benefit + benefit / remainingBeneficiaries.length,
          })
      )
    )
    await uploadPolicy()
    await reloadBeneficiaries()
    EventEmitter.dispatch('toggleLoading', { show: false })
    setShowModal('success')
  }

  const closeModal = () => {
    setBeneficiaryToDelete()
    setShowModal()
  }

  let modal
  switch (showModal) {
    case 'confirmation':
      modal = (
        <BeneficiaryDeletionModal
          beneficiary={beneficiaryToDelete}
          onCancel={closeModal}
          onConfirm={deleteBeneficiary}
        />
      )
      break
    case 'error':
      modal = (
        <BeneficiaryDeletionErrorModal
          onClose={closeModal}
          onConfirm={() => navigate('/users/add-beneficiary')}
        />
      )
      break
    case 'success':
      modal = (
        <BeneficiaryDeletionSuccessModal
          beneficiary={beneficiaryToDelete}
          onClose={closeModal}
        />
      )
      break
    default:
  }

  return (
    <>
      <div className="col-start-2 lg:col-start-1 lg:col-span-5 col-span-10 my-5">
        <h2>Beneficiarios:</h2>
        {beneficiaries &&
          beneficiaries.map(beneficiary => (
            <div
              key={beneficiary.uid}
              className="border-1 shadow rounded border-2 border-gray-400 p-5 my-5"
            >
              <h4 className="mb-2 relative">
                Nombre:
                <div className="absolute flex top-0 right-0">
                  <RiEdit2Fill
                    className="ml-2 text-blue cursor-pointer"
                    onClick={() =>
                      navigate(`/users/edit-beneficiary/${beneficiary.uid}`)
                    }
                  />
                  <BsTrash
                    className="ml-2 text-blue cursor-pointer"
                    onClick={() => selectBeneficiary(beneficiary)}
                  />
                </div>
              </h4>
              <p className="capitalize">
                {' '}
                {beneficiary.name} {beneficiary.lastName}
              </p>
              <h4 className="mb-2">Parentesco:</h4>
              <p className="capitalize"> {beneficiary.relationship}</p>
              <h4 className="mb-2">Beneficio:</h4>
              <p className="mb-0"> {beneficiary.benefit}%</p>
            </div>
          ))}
        <button
          onClick={() => {
            navigate('/users/add-beneficiary')
          }}
          className="default-button px-4 lg:px-4 py-3"
        >
          Agregar beneficario
        </button>
      </div>
      {modal}
    </>
  )
}

export default MyBeneficiariesComponent
