import React from 'react'

// Components
import SuccessIcon from '../../images/success-icon'

// Libs
import classnames from 'classnames'

// Styles
import modalStyles from '../../../components/modals/modal.module.css'

const BeneficiaryDeletionSuccessModal = ({ beneficiary, onClose }) => (
  <>
    <div
      className={classnames(
        modalStyles.modalContainer,
        modalStyles.show,
        'p-8'
      )}
    >
      <div className="grid grid-cols-12 modal-body">
        <div className="col-span-12 w-1/4 mx-auto p-3 mt-1 mb-2">
          <SuccessIcon />
        </div>
        <div className="col-span-12 mx-auto text-center">
          <p>
            El siguiente beneficiario ha sido
            <br />
            eliminado exitosamente:
          </p>
        </div>
        <div className="col-span-12 mx-auto">
          <p className="text-green-500">{`${beneficiary.name} ${beneficiary.lastName}`}</p>
        </div>
        <div className="col-span-12">
          <button
            className="default-button py-3 px-6 mx-auto rounded"
            onClick={onClose}
          >
            Hecho
          </button>
        </div>
      </div>
    </div>
    <div className={classnames(modalStyles.modalBg, modalStyles.show)} />
  </>
)

export default BeneficiaryDeletionSuccessModal
