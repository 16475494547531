import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"

// Components
import Plan from "../../components/plans/plan"

// Services
import { EventEmitter } from "../../services/events"

// Helpers
import { ageBrackets } from "../../helpers/age-brackets"
import buildQuoteData from "../../helpers/build-quote"
import { calculateAge } from "../../helpers/calculate-age"

// Apollo
import { useQuery, gql } from "@apollo/client"

const UpgradeCoverage = ({ location }) => {
  const [selected, setPlanSelected] = useState(false),
    [planData, setPlanData] = useState({}),
    { user, selected: currPlan, userQuote } = location && location.state ? location.state : {},
    age = calculateAge({ dob: userQuote.dob, isFirebaseDate: true }),
    bracket = ageBrackets.reduce(
      (prev, curr) => (age <= curr.max && !prev ? curr.value : prev),
      ""
    ),
    plans = "standard plus full ".replace(
      new RegExp(`^.*?${currPlan.plan}`),
      "")

  const features = " standardFeatures plusFeatures fullFeatures ",
    featureDetails = features
      .split(" ")
      .filter(f => f)
      .map(x => `${x} { accident funerary natural travel partial_disability total_disability accidental_percent }`)
      .join()

  const GET_QUOTES = !!plans
    ? gql`
        query getQuote($bracket: ID!) {
            quote(id: $bracket) {
                    ${plans}
                    ${featureDetails}
            }
        }`
    : gql`
        query getQuote($bracket: ID!) {
          quote(id: $bracket) {
            noQuery
          }
        }
      `
  // Loading data and showing loading modal
  EventEmitter.dispatch("toggleLoading", { show: true })
  let { data, loading } = useQuery(GET_QUOTES, {
    variables: { bracket },
    skip: !plans,
  })
  // When data is received we hide modal
  if (data && data.quote)
    EventEmitter.dispatch("toggleLoading", { show: false })

  useEffect(() => {
    if (data && data.quote) {
      let formattedData = buildQuoteData(data.quote)
      setPlanData(formattedData)
    }
  }, [data, loading])

  return (
    <>
      <div className="grid grid-cols-12 gap-2 mt-5 lg:mt-40">
        {!!plans ? (
          <>
            <div className="lg:col-start-1 col-start-2 col-span-10">
              <h1>Mejora tu cobertura</h1>
              <p className="text-gray-700 text-xl">
                Por favor seleccione un plan para mejorar su cobertura*
              </p>
            </div>
            <div className="col-span-12">
              <div className="grid grid-cols-12 gap-2">
                {planData &&
                  planData.length &&
                  planData.map((pl, i) => {
                    return (
                      <div
                        key={i}
                        className="col-span-10 lg:col-span-4"
                      >
                        <Plan
                          onSelect={setPlanSelected}
                          cardSelected={selected}
                          noButton
                          index={i}
                          plan={pl}
                        />
                      </div>
                    )
                  })}
              </div>
            </div>


            {loading && (
              <>
                <div className="col-span-12">
                  <h1 className="font-normal text-4xl">Loading...</h1>
                </div>
              </>
            )}
            <div className="col-start-2 lg:col-start-1 col-span-8 mt-8">
              <p className="text-gray-700 text-sm">
                *Recuerda que solo pagarás la diferencia con respecto al plan
                que tienes actualmente
              </p>
            </div>
            {!!selected && (
              <div className="col-start-2 lg:col-start-1 text-slide-in col-span-6 mt-2 mb-20">
                <button
                  onClick={() => {
                    navigate("/users/confirm-upgrade", {
                      state: { currPlan, selected, lastPayed: user.lastPayed },
                    })
                  }}
                  className="outlined-default-button py-3 px-4"
                  type="button"
                >
                  Siguiente
                </button>
              </div>
            )}
          </>
        ) : (
          <div className="col-span-9">
            <h1>¡Parece que ya tienes la mejor cobertura!</h1>
            <p className="text-gray-700 text-xl">Alex está orgulloso de ti.</p>
          </div>
        )}
      </div>
    </>
  )
}

export default UpgradeCoverage
