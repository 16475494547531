import React from 'react'

// Components
import Pic from '../images/pic'

// Services
import { getLocalUser } from '../../services/auth'
import { EventEmitter } from '../../services/events'
import functions from '../../services/functions'

const UnverifiedUser = () => {
  const { userId, email } = getLocalUser()

  const resendEmail = async () => {
    EventEmitter.dispatch('toggleLoading', { show: true })
    await functions.post('sendVerificationEmail', { userId }, { json: true })
    EventEmitter.dispatch('toggleLoading', { show: false })
  }

  const reloadPage = () => {
    if (typeof window !== 'undefined') {
      window.location.reload()
    }
  }

  return (
    <div className="lg:w-4/6">
      <div className="w-full mx-auto max-w-xs mt-24 xl:mt-40 px-4 flex flex-col items-center text-center">
        <div className="w-20 mb-4">
          <Pic filename="home/Email.png" />
        </div>
        <p className="italic">{email}</p>
        <p className="font-semibold text-lg py-1">
          Verifica tu correo electrónico
        </p>
        <p>
          Te hemos enviado un correo electrónico a la dirección que nos has
          proporcionado.
        </p>
        <p>
          Por favor dirígite a tu buzón de entrada y confirma que este correo te
          pertenece para continuar con el pago de tu póliza.
        </p>
        <button
          className="default-button mb-2 py-3 px-16 rounded"
          onClick={resendEmail}
        >
          Enviar otra vez
        </button>
        <button
          className="text-gray-800 text-sm underline cursor-pointer mb-8"
          onClick={reloadPage}
        >
          He verificado mi correo
        </button>
      </div>
    </div>
  )
}

export default UnverifiedUser
