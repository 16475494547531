import React, { useState } from 'react'

// Icons
import { BsTrash } from 'react-icons/bs'
import { RiUpload2Line } from 'react-icons/ri'

// Components
import Title from '../global/title'
import Dropzone from '../document-dropzone/dropzone'

// Services
import Cloudinary from '../../services/cloudinary'

export default function UploadID() {
  const [selectedFile, setFile] = useState()
  const cloudinary = Cloudinary()

  const onFileChange = evt => {
    const file = evt.target.files[0]
    if (file) {
      setFile(file)
    }
  }

  const acceptedExtensions = ['.pdf', '.png', '.jpg', '.jpeg']
  const isFileAccepted =
    selectedFile &&
    acceptedExtensions.includes(selectedFile.name.match(/\.[0-9a-z]+$/i)[0])

  return (
    <section>
      <div className="grid grid-cols-12">
        <div className="lg:col-start-2 lg:col-span-6 col-start-2 col-span-10">
          <Title
            main={'Verifica tu identidad'}
            subTitle={[
              'Por favor arrastra o sube alguno de los siguientes documentos:',
            ]}
            options={{ center: false }}
          />
          <Dropzone
            onDrop={acceptedFiles => setFile(acceptedFiles[0])}
            selectedFile={selectedFile}
            acceptedExtensions={acceptedExtensions}
          />
        </div>
        <div className="lg:col-start-2 lg:col-span-6 col-start-2 col-span-10 justify-between mt-4 mb-20">
          {selectedFile && (
            <p className="flex items-center justify-center text-center text-grayText">
              {selectedFile.name}
              <BsTrash
                className="ml-2 text-blue cursor-pointer"
                onClick={() => setFile()}
              />
            </p>
          )}
          <div className="text-center flex justify-between items-baseline">
            <button className="w-full py-2 px-8 hover:bg-gray hover:text-gray-800 border rounded hover:border-black border-blue text-blue">
              <label
                htmlFor="img"
                className="flex items-center justify-center cursor-pointer"
              >
                Subir archivo
                <RiUpload2Line className="ml-2" />
              </label>
              <input
                onChange={onFileChange}
                id="img"
                style={{ display: 'none' }}
                type="file"
                accept={acceptedExtensions}
                className="align-middle ml-2"
              ></input>
            </button>
          </div>
          <div className="text-center text-xs text-grayText leading-5 mt-3">
            <p>
              Formatos de archivo
              <br />
              {acceptedExtensions.join(' ').toUpperCase()}
            </p>
          </div>
          <div className="text-center">
            <button
              className="default-button w-full py-3 px-8"
              type="submit"
              disabled={!isFileAccepted}
              onClick={() => cloudinary.upload(selectedFile)}
            >
              Verificar
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}
