import React, { useState } from 'react'
import classnames from 'classnames'
import { navigate } from 'gatsby'
import { MdAirplanemodeActive } from 'react-icons/md'
import { GiHealthNormal, GiCoffin } from 'react-icons/gi'
import { FaPassport, FaSuitcaseRolling } from 'react-icons/fa'
import { BiHandicap } from 'react-icons/bi'

//CSS
import componentStyle from './plan.module.css'

// Helpers
import formatPrice from '../../helpers/format-price'

const Plan = ({
  plan,
  index,
  quote,
  cardSelected,
  onSelect,
  noButton = false,
  pdf,
  onButtonClick = () => navigate('/quote'),
  buttonText = 'Cotizar',
}) => {
  const { name, price, features } = plan

  const [firstLoad, setLoad] = useState(true)
  const [selected, selectCard] = useState(cardSelected || false)

  const {
    cardContainer,
    hoveredCard,
    cardHeader,
    hoveredHeader,
    cardBody,
    blueHeader,
  } = componentStyle
  const elemContainer = classnames(
    cardContainer,
    'transition-all',
    'duration-300',
    'linear',
    'transform',
    'hover:shadow-2xl',
    {
      [hoveredCard]:
        (index === 1 &&
          firstLoad &&
          cardSelected &&
          cardSelected.name === name) ||
        (selected && cardSelected && cardSelected.name === name),
    }
  )
  const headerContainer = classnames(
    cardHeader,
    'h-20',
    'py-6',
    'border-b',
    'text-center',
    'font-bold',
    { [hoveredHeader]: selected && cardSelected && cardSelected.name === name },
    { [blueHeader]: cardSelected && cardSelected.name === name }
  )
  const buttonContainer = classnames('text-center', 'my-2')

  function onMouseEnter() {
    setLoad(false)
  }

  function _selectCard() {
    selectCard(true)
    return onSelect && typeof onSelect === 'function' ? onSelect(plan) : false
  }

  const mainFeatures = features
    .filter(f => !f.plus)
    .filter(f => f.type !== 'Maximo beneficio')

  // const plusFeatures = features.filter(f => f.plus)

  const priceFloat = +price.trim().replace(',', '.')

  function maxBenefit(price) {
    return parseFloat(price).toFixed(3)
  }

  return (
    <>
      <div
        onClick={_selectCard}
        onMouseEnter={onMouseEnter}
        tabIndex={0}
        className={elemContainer}
        role="row"
        onKeyDown={() => {}}
      >
        <div className={headerContainer}>
          {name}
          {index === 1}
        </div>
        <div className={cardBody}>
          <div className="mt-3">
            <p className="mx-auto my-2 text-center text-lg">Beneficio máximo</p>
            <p className="mb-3 text-center text-blue text-3xl font-bold">
              ${maxBenefit(features[0].price)}
            </p>
          </div>
          <div>
            {/* <div className="flex justify-around items-center text-xs">
            <p className="m-0 px-2 text-green-700 bg-green-400 bg-opacity-25 rounded">
              Ahorra 10%
            </p>
            <div className="flex justify-center text-red">
              <p className="my-2 mx-1 lg:mx-1 text-sm">$</p>
              <p className="my-2 mb-0 line-through text-xl">{price.trim()}</p>
              <p className="my-2 text-center font-medium">/mes</p>
            </div>
          </div> */}
            {quote ? (
              <div className="mt-4 text-center">
                <p className="text-gray-800 text-lg mb-0">
                  ${formatPrice(priceFloat)} /mes
                </p>
                <p className="text-xs text-center mb-0">
                  (Pago anual de{' '}
                  <span className="text-green-500">
                    ${formatPrice(priceFloat * 12)})
                  </span>
                </p>
              </div>
            ) : (
              <div className="mt-4 text-center">
                <p className="text-gray-800 text-lg mb-0">
                  Desde ${formatPrice(priceFloat)} /mes
                </p>
                <p className="text-xs text-center mb-0">
                  (Pago anual de{' '}
                  <span className="text-green-600 font-bold">
                    ${formatPrice(priceFloat * 12)})
                  </span>
                </p>
              </div>
            )}
          </div>

          <div className="mt-3">
            <p className="mx-auto mt-1 mb-0 text-center">Incluye</p>
            <div className="flex justify-center text-gray-800 mt-3">
              <FaPassport className="text-3xl mr-1" />
              <MdAirplanemodeActive className="text-3xl mx-1" />
              <FaSuitcaseRolling className="text-3xl ml-1" />
            </div>
            <div className="flex justify-center text-gray-800 mt-2 mb-3">
              <GiHealthNormal className="text-3xl mr-1" />
              <BiHandicap className="text-3xl mx-1" />
              <GiCoffin className="text-3xl ml-1" />
            </div>
          </div>

          {/* <div>
            {mainFeatures &&
              mainFeatures.length &&
              mainFeatures.map((elem, i) => {
                return elem && elem.active ? (
                  <div key={i} className="flex text-left">
                    <MdCheck className="mt-1" color="green" />{' '}
                    <p className="text-xs mb-1 ml-1">{elem.type}</p>
                    <p className="text-xs font-bold mb-1">
                      {elem.symbol === '$'
                        ? `: ${elem.symbol}${elem.price}`
                        : `: ${elem.price}${elem.symbol}`}
                    </p>
                  </div>
                ) : (
                  <div key={i} className="flex">
                    <MdClose className="opacity-50 mt-1" />{' '}
                    <p className="text-xs text-gray-500 mb-2 ml-1">
                      {elem.type}
                    </p>
                  </div>
                )
              })}
          </div> */}
          {!noButton && (
            <div className={buttonContainer}>
              <button
                onClick={onButtonClick}
                className="default-button py-2 px-16 mx-auto rounded"
              >
                {buttonText}
              </button>
            </div>
          )}
          <div className="text-center mb-2">
            <a
              href={pdf}
              rel="noopener noreferrer"
              target="_blank"
              className="text-gray-800 text-sm underline cursor-pointer"
            >
              Ver todos detalles del plan
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default Plan
