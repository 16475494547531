import firebase from './firebase'
import { EventEmitter } from './events'

// Services
import functions from '../services/functions'

const Cloudinary = () => {
  const upload = async file => {
    try {
      EventEmitter.dispatch('toggleLoading', { show: true })
      const { uid: userId } = await firebase.auth.currentUser
      let form = new FormData()
      form.append('file', file, file.name)
      form.append('userId', userId)
      const headers = { 'Content-Type': `multipart/form-data` }
      const response = await functions.post('upload', form, { headers })
      EventEmitter.dispatch('toggleLoading', { show: false })
      if (response.status === 200) {
        EventEmitter.dispatch('toggleModal', {
          show: true,
          success: true,
          title: 'Documento cargado',
          message:
            '¡Listo! Tu documento de identidad se ha guardado satisfactoriamente',
          navigateTo: '/users/home',
        })
      } else {
        EventEmitter.dispatch('toggleModal', {
          show: true,
          success: false,
          title: '¡Lo sentimos!',
          message:
            'Parece que hubo un problema al cargar tu documento. Por favor intenta de nuevo.',
        })
      }
    } catch (e) {
      EventEmitter.dispatch('toggleLoading', { show: false })
      EventEmitter.dispatch('toggleModal', {
        show: true,
        success: false,
        title: '¡Lo sentimos!',
        message:
          'Parece que hubo un problema al cargar tu documento. Por favor intenta de nuevo.',
      })
    }
  }

  const uploadPolicy = async file => {
    try {
      const { uid: userId } = await firebase.auth.currentUser
      let form = new FormData()
      form.append('file', file, file.name)
      form.append('userId', userId)
      const headers = { 'Content-Type': `multipart/form-data` }
      await functions.post('uploadPolicy', form, { headers })
    } catch (e) {
      // noop
    }
  }

  return { upload, uploadPolicy }
}

export default Cloudinary
