import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'

// Components
import Select from '../global/select'

// Helpers
import { last4 } from '../../helpers/last-4'

// Services
import { getLocalUser } from '../../services/auth'
import firebase from '../../services/firebase'

// Material UI
import MenuItem from '@material-ui/core/MenuItem'



const SelectBankAccount = ({ location }) => {
  const { state = {} } = location
  const { reward = 0 } = state
  const [accounts, setAccount] = useState(null)
  const [userId, setUserId] = useState(null)
  useEffect(() => {
    if (!accounts) {
      getAccountsData()
    }
    async function getAccountsData() {
      const { userId } = getLocalUser()
      let bankAccountsQuery = await firebase.db.collection("bank-accounts").where("userId", "==", userId).get()
      let bankAccounts = []
      bankAccountsQuery.forEach(snap => {
        bankAccounts.push((snap.data()))
      })
      bankAccounts = bankAccounts.map((a, i) => <MenuItem key={i} value={a}>{last4(a.accountNumber)}</MenuItem>)
      setAccount(bankAccounts)
      setUserId(userId)
    }
  })
  let [selected, selectItem] = useState("")
  function handleInputChange(event) {
    const target = event.target
    const value = target.value
    selectItem(value)
  }
  return (
    <>
      <div className='grid grid-cols-12 mt-12 lg:mt-40'>
        <div className='lg:col-start-1 col-start-2 col-span-9'>
          <h1 className='mb-4'>Retirar con transferencia bancaria</h1>
          <p className='text-gray-700 text-xl mb-8'>Por favor seleccione una cuenta bancaria destino</p>
        </div>
        <div className='lg:col-start-1 lg:col-span-6 col-start-2 col-span-9'>
          <Select
            name="withdrawMethod"
            placeholder={"Seleccione una cuenta"}
            value={selected}
            onChange={handleInputChange}
            items={accounts}>
          </Select>
        </div>
        {selected && (
          <div className="lg:col-start-1 col-start-2 col-span-9">
            <button onClick={() => { navigate("/users/confirm-withdraw", { state: { selected, reward } }) }} className="default-button py-3 px-8 mt-4" type="submit">Continuar</button>
          </div>
        )}
        {userId && (
          <div className='lg:col-start-1 col-start-2 col-span-9 lg:col-span-4 mt-12 mb-32'>
            <button onClick={() => { navigate("/users/add-bank-account", { state: { userId, reward } }) }} className="default-button py-3 px-3">+ Agregar una cuenta bancaria</button>
          </div>
        )}
      </div>
    </>
  )

}

export default SelectBankAccount