import React from 'react'

const UserBilling = ({ bills }) => {
  return (
    <>
    { bills && bills.length > 0 && (
      <div className="grid grid-cols-12 mb-10">
        <div className='col-span-10'>
          <p className='mb-4 text-xl bg-gray rounded p-1 font-bold opacity-75'>Facturas</p>
        </div>
          { bills.map((b, i) => (
            <div key={i} className="col-span-10">
              <div className="grid grid-cols-12">
                <div className='col-span-6 lg:col-span-4'>
                  <p className='mb-3 text-gray-600 text-xs'>{b.date.toDateString()}</p>
                  <p className='mb-3 text-xl'>${b.cost} - {b.plan}</p>
                </div>
                <div className='lg:col-start-5 lg:col-span-3 col-start-7 col-span-6'>
                  <button className='bg-black text-white text-xs px-2 m-1 rounded'>Imprimir PDF</button>
                </div>
                <div className="col-span-12">
                  <hr className="w-full h-px bg-black" />
                </div>
              </div>
            </div>
          ))
        }
      </div>
    )}
    </>
  )
}

export default UserBilling