import React, { useState } from "react"
import { navigate } from "gatsby"

// Components
import Select from "../global/select"

// Material UI
import MenuItem from "@material-ui/core/MenuItem"

const WithdrawMethod = ({ location }) => {
  const { state = {} } = location
  const { reward = 0 } = state

  let [selected, selectItem] = useState("bank-transfer")

  function handleInputChange(event) {
    const target = event.target
    const value = target.value
    selectItem(value)
  }

  return (
    <>
      <div className="grid grid-cols-12 mt-12 lg:mt-40">
        <div className="col-start-2 col-span-10 lg:col-start-1 lg:col-span-9">
          <h1 className="mb-4">Retirar recompensas: ${reward}</h1>
          <p className="text-gray-700 text-xl mb-8">
            Por favor seleccione un método de retiro{" "}
          </p>
        </div>
        <div className="col-start-2 col-span-10 lg:col-start-1 lg:col-span-5">
          <Select
            name="withdrawMethod"
            value={selected}
            onChange={handleInputChange}
            placeholder={"Seleccione uno"}
            items={[
              <MenuItem key={0} value="bank-transfer">
                Transferencia bancaria
              </MenuItem>,
            ]}
          ></Select>
        </div>
        <div className="col-start-2 col-span-5 lg:col-start-1 mb-32">
          <button
            onClick={() => {
              navigate("/users/select-bank-account", { state: { reward } })
            }}
            className="default-button w-full py-3 px-8 mt-4"
            type="submit"
          >
            Continuar
          </button>
        </div>
      </div>
    </>
  )
}

export default WithdrawMethod
