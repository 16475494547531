import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'

// Components
import Title from '../global/title'
import Summary from './summary'
import Pic from '../images/pic'
import Input from '../forms/input'
import TosCheckout from '../tos/tos-checkout'
import { HiOutlineClock } from 'react-icons/hi'
import { MdAutorenew } from 'react-icons/md'

// Services
import BraintreeService from '../../services/braintree'
import { EventEmitter } from '../../services/events'
import firebase from '../../services/firebase'
import Referral from '../../services/referral'

// Braintree
import { Braintree, HostedField } from 'react-braintree-fields'

const Checkout = ({ userId, location }) => {
  const [user, setUser] = useState(null),
    [isReady, setReady] = useState(false),
    [tokenize, setTokenizeFunc] = useState(null),
    [refInput, setRefInput] = useState(''),
    [refCode, setRefCode] = useState(''),
    [hasAccepted, setAcceptance] = useState(false),
    { state = {} } = location,
    { selected, months, type, fee } = state,
    recurrence = months ? months : 12,
    [cost] = useState(() =>
      fee ? fee : parseFloat(selected.price.replace(',', '.')) * recurrence
    ),
    features = selected.features.filter(f => f.type === 'Muerte accidental')

  useEffect(() => {
    const loadUser = async () => {
      if (userId && !user) {
        EventEmitter.dispatch('toggleLoading', { show: true })
        const userQuery = await firebase.db
          .collection('users')
          .doc(userId)
          .get()
        const userData = userQuery.data()
        setUser(userData)
        const referralCode = userData.broker
        if (referralCode) {
          const isValidCode = await Referral.checkCode(referralCode, userId)
          if (isValidCode) {
            setRefInput(referralCode)
            setRefCode(referralCode)
          }
        }
        EventEmitter.dispatch('toggleLoading', { show: false })
      }
    }
    loadUser()
  }, [userId, user])

  async function onSubmit() {
    try {
      if (hasAccepted) {
        let updatedRefCode = null
        if (refInput && refInput !== refCode) {
          const isValidCode = await Referral.checkCode(refInput, userId)
          if (!isValidCode) {
            showInvalidCodeModal()
            return
          }
          updatedRefCode = refInput
        }
        const { nonce: paymentMethodId } = await tokenize()
        await BraintreeService.submitTx({
          paymentMethodId,
          selected,
          cost,
          userId,
          type,
          refCode: refCode || updatedRefCode,
        })
      }
    } catch (e) {
      console.error(e)
    }
  }

  async function onAuthorizationSuccess() {
    setReady(true)
  }

  function onDataCollectorInstanceReady(err, dataCollectorInstance) {
    // if (!err) setDevice(dataCollectorInstance.deviceData)
  }

  const openModal = () => {
    EventEmitter.dispatch('toggleModal', {
      show: true,
      success: false,
      noIcon: true,
      title: 'POLÍTICAS DE CANCELACIÓN Y DEVOLUCIÓN',
      content: <TosCheckout />,
    })
  }

  async function getDiscount() {
    if (!refInput) return false
    const isValidCode = await Referral.checkCode(refInput, userId)
    if (isValidCode) {
      setRefCode(refInput)
      EventEmitter.dispatch('toggleModal', {
        show: true,
        success: true,
        title: 'Código verificado',
      })
    } else {
      showInvalidCodeModal()
    }
  }

  function showInvalidCodeModal() {
    EventEmitter.dispatch('toggleModal', {
      show: true,
      success: false,
      title: '¡Código invalido!',
      message: 'Asegurate de que estes introduciendo el código correctamente',
    })
  }

  function handleError(err) {
    if (err.code !== 'HOSTED_FIELDS_FIELD_DUPLICATE_IFRAME') {
      EventEmitter.dispatch('toggleModal', {
        show: true,
        success: false,
        title: '¡Oops!',
        plan: selected.name,
        message:
          'Parece que hay un problema con la información de pago que has proporcionado. Por favor, revisa tus datos y vuelve a intentarlo.',
      })
    }
    EventEmitter.dispatch('toggleLoading', { show: false })
  }

  function onFocus(event) {}

  function onCardTypeChange() {}

  const braintreeAuthorizationToken =
    process.env.GATSBY_ENV === 'local'
      ? 'sandbox_zj6sxc8d_jmsy645jfgkfgzft'
      : 'production_jyr9mht6_3db877ksxh7hcnq8'

  return (
    <>
      <div className="grid grid-cols-12 lg:mb-20">
        <Title _private main={'Paga el plan seleccionado'} />
        <div className="col-start-2 lg:col-start-1 col-span-10 lg:col-span-6">
          <Summary
            promo={getDiscount}
            month={parseFloat(cost) / parseFloat(recurrence)}
            plan={selected.name}
            features={features}
            cost={cost}
          />
          <button
            onClick={() => navigate('/plans')}
            className="outlined-default-button py-1 px-4 my-3 mx-auto rounded"
          >
            Cambiar de plan
          </button>
          <div className="lg:my-3 mt-3">
            <div className="flex">
              {' '}
              <HiOutlineClock className="text-3xl mr-2" />
              <p className="text-sm leading-tight">
                Actualmente todos los planes de Alex son de (1) año de duración,
                con un solo pago anual.
              </p>
            </div>
            <div className="flex">
              {' '}
              <MdAutorenew className="text-3xl mr-2" />
              <p className="text-sm leading-tight">
                El plan se renovará automáticamente, a menos que lo canceles
                antes de la fecha de renovación.
              </p>
            </div>
          </div>
          <label className="flex items-center lg:mb-0 mb-3" htmlFor="terms">
            <Input
              type="checkbox"
              name="terms"
              errors={null}
              classes="lg:mr-2 mr-2 align-middle lg:w-4 lg:h-4 w-6 h-6"
              value={hasAccepted}
              onChange={() => setAcceptance(!hasAccepted)}
            />
            <span className="align-middle">
              <div
                role="button"
                tabIndex={0}
                onKeyDown={() => null}
                onClick={openModal}
                className="text-blue"
              >
                Acepto las políticas de cancelación y devolución
              </div>
            </span>
          </label>
        </div>
        <div className="lg:col-start-7 p-4 lg:p-0 col-span-12 lg:col-span-6 braintree-container">
          <Braintree
            className={isReady && !!user ? '' : 'disabled'}
            authorization={braintreeAuthorizationToken}
            onAuthorizationSuccess={onAuthorizationSuccess}
            onDataCollectorInstanceReady={onDataCollectorInstanceReady}
            onError={handleError}
            onCardTypeChange={onCardTypeChange}
            getTokenRef={ref => setTokenizeFunc(() => ref)}
            styles={{
              input: {
                'font-size': 'inherit',
              },
              ':focus': {
                color: 'blue',
              },
            }}
          >
            <div className="grid grid-cols-12 gap-2">
              <div className="ml-4 col-span-11 lg:col-span-6">
                <h3 className="mb-2">Método de pago</h3>
                <Pic filename="logo/payments.png" />
              </div>
              <div className="col-span-11 lg:col-span-10">
                <input
                  className="tracking-tight"
                  type="text"
                  placeholder="Nombre que aparece en la tarjeta"
                />
              </div>
              <div className="col-span-11 lg:col-span-7">
                <HostedField
                  placeholder="Número de tarjeta"
                  type="number"
                  onFocus={onFocus}
                />
              </div>
              <div className="col-span-6 lg:col-span-3">
                <HostedField placeholder="MM/YY" type="expirationDate" />
              </div>

              <div className="col-span-5 lg:col-span-3">
                <HostedField placeholder="CVV" type="cvv" />
              </div>
              <div className="col-span-11 lg:col-span-4">
                <HostedField
                  placeholder="Código Postal"
                  type="postalCode"
                  onFocus={onFocus}
                />
              </div>
              {type === 'signup' && (
                <div className="flex items-center lg:col-start-1 lg:col-span-10 col-span-11 ">
                  <div className="w-4/5 lg:w-3/5">
                    <input
                      value={refInput}
                      onChange={event => setRefInput(event.target.value)}
                      className="p-2"
                      placeholder="Código Referido"
                    />
                  </div>
                  <button
                    onClick={getDiscount}
                    className="outlined-default-button py-2 px-4 ml-6 mb-4 rounded"
                  >
                    Validar
                  </button>
                  <div className="ml-2 mt-2">
                    <p className="text-lightGray text-sm">(Opcional)</p>
                  </div>
                </div>
              )}
              <div className="col-span-11 lg:col-span-5 lg:mb-0 mb-12">
                <button
                  className="tracking-wide ml-4 pt-3 pb-2 border-b-2 border-green-500 hover:border-green-600 hover:border-b-2 hover:bg-green-600 shadow-lg font-bold rounded bg-green-500 text-white w-full"
                  onClick={onSubmit}
                >
                  PAGAR
                </button>
              </div>
            </div>
          </Braintree>
        </div>
      </div>
    </>
  )
}

export default Checkout
