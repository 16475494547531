import React, { Component } from "react"

// Components
import Input from "./input"

// Validation
import validate from "validate.js"
import addBankAccountValidation from "./validators/add-bank-account"

// Services
import firebase from "../../services/firebase"
import { EventEmitter } from "../../services/events"

export default class AddBankAccountForm extends Component {
  constructor(props) {
    super(props)
    this.firebase = firebase
  }

  state = {
    accountHolder: "",
    routingNumber: "",
    swift: "",
    accountNumber: "",
    address: "",
    country: "",
    errors: {},
  }

  /**
   * Handle input change
   * @param {Object} event
   */
  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  /**
   * Validates input values onBlur event
   */
  validateInput = async event => {
    const target = event.target
    const value = target.value
    const name = target.name
    const validationError = validate.single(
      value,
      addBankAccountValidation[name]
    )
    if (validationError && validationError.length) {
      this.setState({
        errors: { ...this.state.errors, [name]: [validationError[0]] },
      })
    } else {
      this.setState({ errors: { ...this.state.errors, [name]: [] } })
    }
  }

  /**
   * Submit forms
   * @param {Object} event
   */
  handleSubmit = async event => {
    event.preventDefault()
    try {
      const {
        accountHolder,
        routingNumber,
        swift,
        accountNumber,
        address,
        country,
      } = this.state
      const { userId, reward } = this.props
      const validationErrors = validate(
        {
          accountHolder,
          routingNumber,
          swift,
          accountNumber,
          address,
          country,
        },
        addBankAccountValidation,
        { fullMessages: false }
      )
      if (validationErrors)
        return this.setState({
          errors: { ...this.state.errors, ...validationErrors },
        })

      // Showing loading before operation and then hiding it
      EventEmitter.dispatch("toggleLoading", { show: true })

      await this.firebase.db
        .collection("bank-accounts")
        .add({
          accountHolder,
          routingNumber,
          swift,
          accountNumber,
          address,
          country,
          userId
        })
      EventEmitter.dispatch("toggleLoading", { show: false })
      EventEmitter.dispatch("toggleModal", {
        show: true,
        success: true,
        title: "Cuenta bancaria agregada",
        message: "Cuenta bancaria agregada con éxito.",
        navigateTo: "/users/select-bank-account",
        routeParams: { reward },
      })
    } catch (e) {
      EventEmitter.dispatch("toggleModal", {
        show: true,
        success: false,
        title: "¡Lo sentimos!",
        message: "En este momento no podemos registrar tu cuenta bancaria.",
      })
    }
  }

  render() {
    return (
      <div className="container mx-auto mt-5 text-slide-in">
        <div className="grid grid-cols-12">
          <div className="col-span-12 lg:col-span-8 text-center">
            <form onSubmit={this.handleSubmit}>
              <div className="form-input">
                <Input
                  classes="w-full bg-black p-4 focus:bg-white focus:text-black"
                  type="text"
                  name="accountHolder"
                  placeholder="Titular de la cuenta"
                  value={this.state.accountHolder}
                  errors={this.state.errors["accountHolder"]}
                  onChange={this.handleInputChange}
                  onBlur={this.validateInput}
                />
              </div>
              <div className="form-input">
                <Input
                  className="w-full bg-black p-4 focus:bg-white focus:text-black"
                  type="text"
                  name="routingNumber"
                  placeholder="ABA"
                  value={this.state.routingNumber}
                  onChange={this.handleInputChange}
                  errors={this.state.errors["routingNumber"]}
                  onBlur={this.validateInput}
                />
              </div>
              <div className="form-input">
                <Input
                  className="w-full bg-black p-4 focus:bg-white focus:text-black"
                  type="text"
                  name="swift"
                  placeholder="Swift"
                  value={this.state.swift}
                  onChange={this.handleInputChange}
                  errors={this.state.errors["swift"]}
                  onBlur={this.validateInput}
                />
              </div>
              <div className="form-input">
                <Input
                  className="w-full bg-black p-4 focus:bg-white focus:text-black"
                  type="text"
                  name="accountNumber"
                  placeholder="Número de cuenta"
                  value={this.state.accountNumber}
                  onChange={this.handleInputChange}
                  errors={this.state.errors["accountNumber"]}
                  onBlur={this.validateInput}
                />
              </div>
              <div className="form-input">
                <Input
                  className="w-full bg-black p-4 focus:bg-white focus:text-black"
                  type="text"
                  name="address"
                  placeholder="Dirección"
                  value={this.state.address}
                  onChange={this.handleInputChange}
                  errors={this.state.errors["address"]}
                  onBlur={this.validateInput}
                />
              </div>
              <div className="form-input">
                <Input
                  className="w-full bg-black p-4 focus:bg-white focus:text-black"
                  type="text"
                  name="country"
                  placeholder="País"
                  value={this.state.country}
                  onChange={this.handleInputChange}
                  errors={this.state.errors["country"]}
                  onBlur={this.validateInput}
                />
              </div>
              <button
                className="w-full default-button py-3 px-8 mb-48 mt-6"
                type="submit"
              >
                Confirmar
              </button>
            </form>
          </div>
        </div>
      </div>
    )
  }
}
