import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'

// Components
import BeneficaryForm from '../forms/beneficiary-form'

// Services
import firebase from '../../services/firebase'
import { EventEmitter } from '../../services/events'

const EditBeneficiary = ({ userId, beneficiaryId }) => {
  const [beneficiary, setBeneficiary] = useState()
  const [beneficiaries, setBeneficiaries] = useState()

  useEffect(() => {
    const loadBeneficiaries = async () => {
      try {
        EventEmitter.dispatch('toggleLoading', { show: true })
        if (userId) {
          let beneficiariesQuery = await firebase.db
            .collection('beneficiaries')
            .where('userId', '==', userId)
            .get()
          let beneficiariesData = []
          beneficiariesQuery.forEach(snap => {
            beneficiariesData.push({ uid: snap.id, ...snap.data() })
          })
          const beneficiary = beneficiariesData.find(
            b => b.uid === beneficiaryId
          )
          if (!beneficiary) {
            navigate('/users/home')
            return
          }
          setBeneficiary(beneficiary)
          setBeneficiaries(
            beneficiariesData.filter(b => b.uid !== beneficiaryId)
          )
        }
      } catch (e) {
        setBeneficiaries([])
      } finally {
        EventEmitter.dispatch('toggleLoading', { show: false })
      }
    }
    loadBeneficiaries()
  }, [userId, beneficiaryId])

  return (
    <div className="grid grid-cols-12 mt-5 lg:mt-40">
      <div className="col-start-2 lg:col-start-1 col-span-10">
        <h1>Editar beneficiario</h1>
        <p className="text-gray-700 text-lg lg:text-xl">
          Ingrese los datos del beneficiario de la póliza
        </p>
      </div>
      <div className="col-span-12">
        {beneficiaries && (
          <BeneficaryForm
            beneficiary={beneficiary}
            beneficiaries={beneficiaries}
          />
        )}
      </div>
    </div>
  )
}

export default EditBeneficiary
