import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { RiExternalLinkLine } from 'react-icons/ri'

// Components
import Title from '../global/title'
import MyBeneficiariesComponent from './user-beneficiaries'

// Services
import firebase from '../../services/firebase'
import { EventEmitter } from '../../services/events'
import { logout, setUser as setLocalUser } from '../../services/auth'

import uploadPolicy from '../../helpers/upload-policy'

// Test file
import claimPdf from '../../data/claim.pdf'
import procedurePdf from '../../data/procedure.pdf'
import condicionadoA from '../../data/condicionado-one.pdf'
import condicionadoB from '../../data/condicionado-funeral.pdf'
import condicionadoC from '../../data/condicionado-travelRider-new.pdf'
import condicionadoD from '../../data/condicionado-vidaRider.pdf'

const MyPolicyComponent = ({ userId }) => {
  const [user, setUser] = useState(null)
  const [selected, setSelected] = useState(null)
  const [userQuote, setUserQuote] = useState(null)
  const [beneficiaries, setBeneficiaries] = useState(null)

  useEffect(() => {
    const loadUser = async () => {
      if (userId && !user) {
        EventEmitter.dispatch('toggleLoading', { show: true })
        const user = await firebase.db
          .collection('users')
          .doc(userId)
          .get()
        const selected = await firebase.db
          .collection('policies')
          .doc(userId)
          .get()
        const quote = await firebase.db
          .collection('user-quotes')
          .doc(userId)
          .get()
        const beneficiariesQuery = await firebase.db
          .collection('beneficiaries')
          .where('userId', '==', userId)
          .get()
        const beneficiariesData = []
        beneficiariesQuery.forEach(snap => {
          beneficiariesData.push({ uid: snap.id, ...snap.data() })
        })
        const userInfo = user.data()
        const selectedInfo = selected.data()
        const quoteInfo = quote.data()
        if (!userInfo) {
          logout(() => navigate('/'))
          return
        }
        if (!userInfo.lastPayed) {
          navigate('/users/checkout', {
            state: { selected: selectedInfo, type: 'signup' },
          })
          return
        }
        if (!userInfo.documentId) {
          navigate('/users/upload')
          return
        }
        if (beneficiariesData && beneficiariesData.length === 0) {
          navigate('/users/add-beneficiary')
          return
        }
        EventEmitter.dispatch('toggleLoading', { show: false })
        setUser(userInfo)
        setSelected(selectedInfo)
        setUserQuote(quoteInfo)
        setBeneficiaries(beneficiariesData)
      }
    }
    loadUser()
  }, [userId, user])

  useEffect(() => {
    const updateUser = async () => {
      if (
        user &&
        !user.policyUrl &&
        beneficiaries &&
        beneficiaries.length > 0
      ) {
        EventEmitter.dispatch('toggleLoading', { show: true })
        await uploadPolicy()
        const userQuery = await firebase.db
          .collection('users')
          .doc(user.userId)
          .get()
        const userData = userQuery.data()
        setLocalUser(userData)
        setUser(userData)
        EventEmitter.dispatch('toggleLoading', { show: false })
      }
    }
    updateUser()
  }, [user, beneficiaries])

  const reloadBeneficiaries = async () => {
    const beneficiariesQuery = await firebase.db
      .collection('beneficiaries')
      .where('userId', '==', userId)
      .get()
    const beneficiariesData = []
    beneficiariesQuery.forEach(snap => {
      beneficiariesData.push({ uid: snap.id, ...snap.data() })
    })
    setBeneficiaries(beneficiariesData)
  }

  return user ? (
    <>
      <div className="grid grid-cols-12">
        <Title _private main={'Mi Póliza'} />
        <div className="col-start-2 lg:col-start-1 col-span-10">
          <p className="mb-1">
            <span className="font-semibold capitalize">Titular: </span>
            {user.name} {user.lastName}
          </p>
          <p className="mb-1">
            <span className="font-semibold">Póliza Nº:</span>{' '}
            {user.policyNumber}
          </p>

          {selected && (
            <>
              <p className="mb-1 font-semibold">
                Prima Anual:{' '}
                <span className="font-normal">
                  $
                  {parseFloat(selected.price.replace(',', '.') * 12).toFixed(2)}
                </span>
              </p>
              <p className="mb-1 font-semibold">
                Plan Contratado:{' '}
                <span className="font-normal">Alex {selected.name}</span>
              </p>
              <p className="mb-3">
                <span className="font-semibold">Cobertura Máxima:</span> $
                {selected.features[0].price}
              </p>
              {/* <p className="mb-2"><a className="transition duration-700 text-standard-blue hover:underline mt-2" rel="noopener noreferrer" target="_blank" href={getPdf(selected.name)}> Ver todos los detalles de la póliza (PDF)</a></p> */}
              {user.policyUrl && (
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={user.policyUrl}
                >
                  <div className="flex items-center justify-between lg:w-2/6 cursor-pointer outlined-darker-button mb-3 px-4 py-1 mr-2">
                    Ver Certificado de Cobertura
                    <RiExternalLinkLine />
                  </div>
                </a>
              )}
              <a rel="noopener noreferrer" target="_blank" href={condicionadoA}>
                <div className="flex items-center justify-between lg:w-2/6 cursor-pointer outlined-darker-button mb-3 px-4 py-1 mr-2">
                  Ver Accidentes Personales
                  <RiExternalLinkLine />
                </div>
              </a>
              <a rel="noopener noreferrer" target="_blank" href={condicionadoD}>
                <div className="flex items-center justify-between lg:w-2/6 cursor-pointer outlined-darker-button mb-3 px-4 py-1 mr-2">
                  Ver Seguro de Vida
                  <RiExternalLinkLine />
                </div>
              </a>
              <a rel="noopener noreferrer" target="_blank" href={condicionadoB}>
                <div className="flex items-center justify-between lg:w-2/6 cursor-pointer outlined-darker-button mb-3 px-4 py-1 mr-2">
                  Ver Asistencia Funeraria
                  <RiExternalLinkLine />
                </div>
              </a>
              <a rel="noopener noreferrer" target="_blank" href={condicionadoC}>
                <div className="flex items-center justify-between lg:w-2/6 cursor-pointer outlined-darker-button mb-3 px-4 py-1 mr-2">
                  Ver Asistencia al Viajero
                  <RiExternalLinkLine />
                </div>
              </a>
            </>
          )}
          {/* {user && selected && userQuote && (
            <div className="col-start-2 lg:col-start-1 col-span-10">
              <button
                onClick={() => {
                  navigate('/users/upgrade-coverage', {
                    state: { user, selected, userQuote },
                  })
                }}
                className="default-button mb-5 px-5 py-3"
              >
                Mejorar cobertura
              </button>
            </div>
          )} */}
        </div>
        <MyBeneficiariesComponent
          beneficiaries={beneficiaries}
          reloadBeneficiaries={reloadBeneficiaries}
        />
        <div className="col-start-2 lg:col-start-1 col-span-10 pb-10">
          <p className="font-semibold mb-3">Proveedor de seguros:</p>
          <p>Redbridge Insurance Co. Ltd.</p>
          <p className="mb-3 font-semibold">Para contacto y reclamos:</p>
          <p className="mb-0">+1 (305) 232-9040</p>
          <a
            href="mailto:riclclaims@redbridgeinsurance.com"
            rel="noopener noreferrer"
            target="_blank"
          >
            <p className="hover:text-gray-600 hover:underline">
              riclclaims@redbridgeinsurance.com
            </p>
          </a>
          <a
            href="mailto:claimstatus@redbridgeinsurance.com"
            rel="noopener noreferrer"
            target="_blank"
          >
            <p className="hover:text-gray-600 hover:underline">
              claimstatus@redbridgeinsurance.com
            </p>
          </a>
          <p className="mb-4 font-semibold">Descargables:</p>
          <a rel="noopener noreferrer" target="_blank" href={procedurePdf}>
            <div className="flex items-center justify-between lg:w-2/5 cursor-pointer outlined-default-button mb-5 px-4 py-2 mr-2">
              Procedimiento de reclamación
              <RiExternalLinkLine />
            </div>
          </a>
          <a rel="noopener noreferrer" target="_blank" href={claimPdf}>
            <div className="flex items-center justify-between lg:w-2/5 cursor-pointer outlined-default-button mb-5 px-4 py-2 mr-2">
              Formulario de reclamación
              <RiExternalLinkLine />
            </div>
          </a>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="grid grid-cols-12">
        <div className="col-start-2 col-span-12 mt-10 lg:mt-40 lg:col-start-1">
          <h1 className="font-normal text-4xl">Cargando...</h1>
        </div>
      </div>
    </>
  )
}

export default MyPolicyComponent
