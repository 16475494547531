import React from 'react'
import { navigate } from 'gatsby'

// Helpers
import formatNum from '../../helpers/format-number'


const ConfirmUpgrade = ({ location }) => {

  /**
   * Returns the difference in month
   * between 2 dates
   * @param {Date} dateFrom 
   * @param {Date} dateTo 
   */
  function monthDiff(dateFrom, dateTo) {
    return dateTo.getMonth() - dateFrom.getMonth() +
      (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
  }

  const { currPlan, selected, lastPayed } = location && location.state ? location.state : {}

  if (!lastPayed || !lastPayed.seconds) {
    navigate("/users/home")
    return null
  }

  const planCreationDate = new Date(lastPayed.seconds * 1000),
    monthlyDiff = parseFloat(selected.price) - parseFloat(currPlan.price),
    day = planCreationDate.getDate(),
    month = planCreationDate.getMonth(),
    year = planCreationDate.getFullYear(),
    expirationDate = new Date(year + 1, month, day),
    remainingMonths = monthDiff(planCreationDate, expirationDate),
    fee = formatNum(monthlyDiff * remainingMonths)

  return (
    <>
      <div className='grid grid-cols-12 mt-12 lg:mt-40'>
        <div className='col-start-2 col-span-10'>
          <h1 className='mb-4'>Confirmar mejora de cobertura</h1>
          <p className='text-gray-700 text-xl mb-12'>Por favor verifique los datos y confirme su mejora</p>
        </div>
        <div className='col-start-2 col-span-8'>
          <p className='text-xl underline'>Detalles de la mejora:</p>
          <p className='text-xl'>Plan actual: <span className='font-bold line-through'>{currPlan.name}</span></p>
          <p className='text-xl'>Nuevo plan: <span className='font-bold'>{selected.name}</span></p>
          <p className='text-xl'>Diferencia entre planes: <span className='font-bold'>${fee}</span></p>
          <p className='text-xl'>Cantidad meses restantes: <span className='font-bold'>{remainingMonths}</span></p>
        </div>
        <div className="col-start-2 col-span-8">
          <button onClick={() => { navigate("/users/checkout", { state: { selected, fee, months: remainingMonths, type: "upgrade" } }) }} className="default-button py-3 px-8 mb-48 mt-6" type="submit">
            Pagar
          </button>
        </div>
      </div>
    </>
  )
}

export default ConfirmUpgrade