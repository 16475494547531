import React from 'react'

// Helpers
import { last4 } from '../../helpers/last-4'

// Services
import { EventEmitter } from '../../services/events'
import { getLocalUser } from '../../services/auth'
import firebase from '../../services/firebase'



const ConfirmWithdraw = ({ location }) => {
  const { state = {} } = location
  const { reward, selected } = state

  /**
   * Saves a withdrawal request
   * in DB
   */
  async function confirmWithdrawal() {
    try {
      const { userId } = getLocalUser()
      await firebase.db.collection("/withdrawals").add({ reward, accountNumber: selected.accountNumber, status: 'pending', userId })
      EventEmitter.dispatch("toggleModal", {
        show: true, success: true, title: "Retiro de recompensa",
        message: "Su solicitud de retiro de recompensa esta siendo procesada.",
        navigateTo: "/users/home"
      })
    } catch (e) {
      EventEmitter.dispatch("toggleModal", {
        show: true, success: false, title: "¡Lo sentimos!",
        message: "En este momento no podemos procesar retiro de recompensas."
      })
    }
  }
  return (
    <>
      <div className='grid grid-cols-12 mt-12 lg:mt-40'>
        <div className='lg:ml-0 col-start-2 lg:col-start-2 col-span-12'>
          <h1 className='mb-4'>Confirmar retiro de recompensas</h1>
          <p className='text-gray-700 text-xl mb-12'>Por favor verifique los datos y confirme su retiro</p>
        </div>
        <div className='col-start-2 lg:col-start-2 col-span-8'>
          <p className='text-xl underline'>Detalles del retiro:</p>
          <p className='text-xl'>Monto: <span className='font-bold'>${reward}</span></p>
          <p className='text-xl'>Cuenta destino: <span className='font-bold'>{last4(selected.accountNumber)}</span></p>
        </div>
        <div className="col-start-2 lg:col-start-2 col-span-8">
          <button onClick={confirmWithdrawal} className="default-button py-3 px-8 mb-48 mt-6" type="button">CONFIRMAR Y RETIRAR</button>
        </div>
      </div>
    </>
  )

}

export default ConfirmWithdraw