import classnames from 'classnames'
import React from 'react'
import Dropzone from 'react-dropzone'

// Components
import FailureIcon from '../images/failure-icon'
import SuccessIcon from '../images/success-icon'

// Images
import DocumentIcon from '../../svg/document-icon.svg'

export default function drop({ onDrop, selectedFile, acceptedExtensions }) {
  const success = (
    <div className="absolute w-full inset-0 flex flex-col items-center justify-center border border-green-600 rounded">
      <div className="w-full grid grid-cols-12">
        <div className="col-span-12 w-1/4 mx-auto p-3 mt-1 mb-2">
          <SuccessIcon />
        </div>
      </div>
      <SuccessIcon />
      <p className="text-lg text-center">Tu documento ha sido cargado!</p>
    </div>
  )
  const failure = (
    <div className="absolute w-full inset-0 flex flex-col items-center justify-center border border-red rounded">
      <div className="w-full grid grid-cols-12">
        <div className="col-span-12 w-1/4 mx-auto p-3 mt-1 mb-2">
          <FailureIcon />
        </div>
      </div>
      <SuccessIcon />
      <p className="text-lg text-center">
        No hemos podido cargar tu documento.
        <br /> Por favor intentalo de nuevo.
      </p>
    </div>
  )
  const isFileAccepted =
    selectedFile &&
    acceptedExtensions.includes(selectedFile.name.match(/\.[0-9a-z]+$/i)[0])

  return (
    <Dropzone onDrop={onDrop}>
      {({ getRootProps, getInputProps }) => (
        <section>
          <div className="relative" {...getRootProps()}>
            <input {...getInputProps()} accept={acceptedExtensions} />
            <div
              className={classnames({
                invisible: selectedFile,
              })}
            >
              <div className="border border-dashed border-blue hover:border-black rounded shadow-lg cursor-pointer">
                <div className="flex mx-2 my-10">
                  <DocumentIcon className="mt-3" />
                  <ul className="list-disc my-0">
                    <li>Pasaporte</li>
                    <li>Cédula de Identidad</li>
                    <li>DNI</li>
                    <li>Licencia de Conducir</li>
                  </ul>
                </div>
                <div className="mx-auto">
                  <p className="text-xs font-light text-gray-800 text-center mx-auto">
                    Asegúrate que la foto sea de buena calidad y no se vea
                    borrosa
                  </p>
                </div>
              </div>
            </div>
            {selectedFile && (isFileAccepted ? success : failure)}
          </div>
        </section>
      )}
    </Dropzone>
  )
}
