export default {
    accountHolder: {
        presence: { allowEmpty: false, message: "Campo requerido." }
    },
    routingNumber: {
        presence: { allowEmpty: false, message: "Campo requerido." }
    },
    swift: {
        presence: { allowEmpty: false, message: "Campo requerido." }
    },
    accountNumber: {
        presence: { allowEmpty: false, message: "Campo requerido" }
    },
    address: {
        presence: { allowEmpty: false, message: "Dirección de la cuenta" }
    },
    country: {
        presence: { allowEmpty: false, message: "País de la cuenta" }
    }

}