import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'

// Icons
import { RiFileCopy2Line } from 'react-icons/ri'
import { MdCheck } from 'react-icons/md'

// Components
import Title from '../global/title'

// Services
import { getLocalUser } from '../../services/auth'
import firebase from '../../services/firebase'
import { EventEmitter } from '../../services/events'

const UserReferralsPage = ({ location }) => {
  const [data, setUserData] = useState(null)
  const [referrals, setReferrals] = useState(null)
  const [urlCopied, setUrlCopied] = useState(false)

  useEffect(() => {
    async function getUserData() {
      EventEmitter.dispatch('toggleLoading', { show: true })
      const { userId } = getLocalUser()
      const user = await firebase.db
        .collection('users')
        .doc(userId)
        .get()
      let referral = await firebase.db
        .collection('referrals')
        .doc(userId)
        .get()
      if (user.exists) {
        setUserData(user.data())
      }
      if (referral.exists) {
        setReferrals(referral.data())
      }
      EventEmitter.dispatch('toggleLoading', { show: false })
    }
    getUserData()
  }, [])

  const addReferralUrlToClipboard = () => {
    if (navigator) {
      navigator.clipboard.writeText(
        `${location.origin}/quote?refCode=${data.referralCode.toUpperCase()}`
      )
      setUrlCopied(true)
    }
  }

  return data ? (
    <>
      <div className="grid grid-cols-12">
        <Title _private main={'Referidos'} />
        <div className="col-start-2 lg:col-start-1 col-span-10 mb-2">
          <p className="mb-4 text-2xl font-semibold">
            Balance:{' '}
            <span className="text-2xl text-green-500">
              ${referrals?.pendingBalance || 0}
            </span>
          </p>
          <p className="mb-3">
            Mi código:{' '}
            <span className="font-semibold">
              {data.referralCode.toUpperCase()}
            </span>
          </p>
          <p className="mb-3 text-blue text-sm">
            <button
              className="p-1 inline-flex items-center border rounded border-blue hover:bg-blue hover:bg-opacity-50 focus:outline-none"
              onClick={addReferralUrlToClipboard}
            >
              Compartir mi código
              {urlCopied ? (
                <MdCheck className="ml-2 inline-block" color="green" />
              ) : (
                <RiFileCopy2Line className="ml-2" />
              )}
            </button>
          </p>
          <p className="mb-3">
            Referidos:{' '}
            <span className="font-semibold">{referrals?.referred || 0} </span>
          </p>
          <p className="mb-3">
            Recompensas completadas:{' '}
            <span className="font-semibold">
              ${referrals?.rewards - referrals?.pendingBalance || 0}
            </span>
          </p>
          <p className="mb-3">
            Recompensas pendientes:{' '}
            <span className="font-semibold">
              {' '}
              ${referrals?.pendingBalance || 0}
            </span>
          </p>
        </div>
        <div className="col-start-2 lg:col-start-1 col-span-3">
          <button
            className="w-full default-button py-3 mb-32 text-center"
            onClick={() => {
              navigate('/users/withdraw', {
                state: { reward: data.pendingBalance },
              })
            }}
          >
            Retirar
          </button>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="grid grid-cols-12">
        <div className="col-start-2 col-span-12 mt-10 lg:mt-40 lg:col-start-1">
          <h1 className="font-normal text-4xl">Cargando...</h1>
        </div>
      </div>
    </>
  )
}

export default UserReferralsPage
