// Services
import { EventEmitter } from '../services/events'
import functions from '../services/functions'

/**
 * Referral Service
 */
const ReferralService = () => {
  /**
   * Checks if referral code is valid
   * @param {String} code - Referral code
   * @param {String} userId - User making the request
   * @returns {Object} Response data
   */
  const checkCode = async (code, userId) => {
    try {
      EventEmitter.dispatch('toggleLoading', { show: true })
      const response = await functions.post(
        'check',
        { refCode: code, userId },
        { json: true }
      )
      EventEmitter.dispatch('toggleLoading', { show: false })
      if (response.status === 200) return response.data.success
      return new Error('Unable to check refCode')
    } catch (e) {
      EventEmitter.dispatch('toggleLoading', { show: false })
    }
  }

  return { checkCode }
}
export default ReferralService()
