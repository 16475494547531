import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Slider from '@material-ui/core/Slider'

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
})

export default function DiscreteSlider({
  title,
  onChange,
  benefit,
  max = 100,
  disabled,
}) {
  const classes = useStyles()

  const marks = [
    {
      value: 10,
      label: '10%',
    },

    {
      value: 50,
      label: '50%',
    },

    {
      value: max,
      label: `${max}%`,
    },
  ]

  function valuetext(value) {
    return `${value}%`
  }

  return (
    <div className={classes.root}>
      <Typography id="discrete-slider" gutterBottom>
        {title}
      </Typography>
      <Slider
        defaultValue={benefit}
        getAriaValueText={valuetext}
        aria-labelledby="discrete-slider"
        step={10}
        valueLabelDisplay="on"
        marks={marks}
        min={10}
        onChange={onChange}
        max={max}
        disabled={disabled}
      />
    </div>
  )
}
