import React, { Component } from 'react'
import { RiLock2Line } from 'react-icons/ri'

// Material UI
import MenuItem from '@material-ui/core/MenuItem'

// Components
import DatePicker from '../global/date-picker'
import Input from './input'
import Select from '../global/select'
import Slider from '../global/slider'

// Firebase
import { getLocalUser } from '../../services/auth'
import firebase from '../../services/firebase'
import { EventEmitter } from '../../services/events'

// Validation
import validate from 'validate.js'
import addBeneficiaryValidation from './validators/add-beneficiary'

// Helpers
import uploadPolicy from '../../helpers/upload-policy'

export default class BeneficiaryForm extends Component {
  constructor(props) {
    super(props)
    this.firebase = firebase
  }

  state = {
    name: this.props.beneficiary?.name || '',
    lastName: this.props.beneficiary?.lastName || '',
    dob: this.props.beneficiary?.dob
      ? new Date(this.props.beneficiary.dob.seconds * 1000)
      : '',
    relationship: this.props.beneficiary?.relationship || '',
    benefit:
      this.props.beneficiary?.benefit ||
      (this.props.beneficiaries.length === 0 ? 100 : 50),
    errors: {},
    relationships: [
      'Cónyuge',
      'Abuelo(a)',
      'Padre/Madre',
      'Hermano(a)',
      'Hijo(a)',
      'Tío(a)',
      'Otro',
    ].map(o => (
      <MenuItem key={o} value={o}>
        {o}
      </MenuItem>
    )),
  }

  /**
   * Handle input change
   * @param {Object} event
   */
  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  /**
   * Handles date change
   * @param {Date} date
   */
  handleDateChange = date => {
    this.setState({ dob: date, errors: { dob: [] } })
  }

  handleSliderChange = (event, value) => {
    this.setState({
      benefit: value,
    })
  }

  /**
   * Validates input values onBlur event
   */
  validateInput = async event => {
    const target = event.target
    const value = target.value
    const name = target.name
    const validationError = validate.single(
      value,
      addBeneficiaryValidation[name]
    )
    if (validationError && validationError.length) {
      this.setState({
        errors: { ...this.state.errors, [name]: [validationError[0]] },
      })
    } else {
      this.setState({ errors: { ...this.state.errors, [name]: [] } })
    }
  }

  /**
   * Submit forms
   * @param {Object} event
   */
  handleSubmit = async event => {
    event.preventDefault()
    try {
      const { beneficiary } = this.props
      const { name, lastName, dob, relationship, benefit } = this.state
      const formData = { name, lastName, dob, relationship, benefit }
      const validationErrors = validate(formData, addBeneficiaryValidation)
      if (validationErrors) {
        return this.setState({
          errors: { ...this.state.errors, ...validationErrors },
        })
      }

      EventEmitter.dispatch('toggleLoading', { show: true })
      const { userId, policyUrl } = getLocalUser()
      let beneficiariesQuery = await firebase.db
        .collection('beneficiaries')
        .where('userId', '==', userId)
        .get()
      let beneficiaries = []
      beneficiariesQuery.forEach(snap => {
        beneficiaries.push({ uid: snap.id, ...snap.data() })
      })
      if (beneficiary) {
        beneficiaries = beneficiaries.filter(b => b.uid !== beneficiary.uid)
      }
      if (beneficiaries.length > 2) {
        EventEmitter.dispatch('toggleLoading', { show: false })
        return EventEmitter.dispatch('toggleModal', {
          show: true,
          success: false,
          title: 'Número máximo de beneficiarios',
          message:
            'Ya has definido el número máximo de beneficiarios para tu poliza',
        })
      } else if (beneficiaries) {
        const newBenefits = this.calculateNewBenefits(
          beneficiaries.map(b => b.benefit)
        )
        for (const [index, beneficiary] of beneficiaries.entries()) {
          const { uid } = beneficiary
          await firebase.db
            .collection('beneficiaries')
            .doc(uid)
            .update({ benefit: newBenefits[index] })
        }
      }

      if (beneficiary) {
        await this.firebase.db
          .collection('beneficiaries')
          .doc(beneficiary.uid)
          .set({ ...formData, userId })
      } else {
        await this.firebase.db.collection('beneficiaries').add({
          ...formData,
          userId,
        })
      }

      if (policyUrl) {
        await uploadPolicy()
      }

      console.log('Here!')
      EventEmitter.dispatch('toggleLoading', { show: false })
      EventEmitter.dispatch('toggleModal', {
        show: true,
        success: true,
        title: 'Beneficiario agregado',
        message:
          beneficiaries.length < 2
            ? 'Has agregado un nuevo beneficiario a tu póliza'
            : 'Has agregado la máxima cantidad de beneficiarios permitidos',
        email: `Hemos enviado la información a su correo electrónico`,
        navigateTo: '/users/home',
        buttons: beneficiaries.length !== 2 && [
          {
            text: 'Agregar otro beneficiario',
            navigateTo: '/users/new-beneficiary',
          },
          { text: 'Ir a mi cuenta', navigateTo: '/users/home' },
        ],
      })
    } catch (e) {
      console.error(e)
      EventEmitter.dispatch('toggleLoading', { show: false })
      EventEmitter.dispatch('toggleModal', {
        show: true,
        success: false,
        title: '!Lo sentimos!',
        message: 'En este momento no podemos agregar a un nuevo beneficiario',
      })
    }
  }

  calculateNewBenefits(currentBenefits) {
    const newBenefits = currentBenefits.map(benefit => {
      let currentBenefit = this.props.beneficiary?.benefit || 0
      return benefit + (currentBenefit - this.state.benefit) / currentBenefits.length
    })

    do {
      const lowBenefitsIndexes = []
      let difference = 0

      newBenefits.forEach((benefit, index) => {
        if (benefit <= 10) {
          lowBenefitsIndexes.push(index)
          difference += 10 - benefit
        }
      })

      newBenefits.forEach((benefit, index, array) => {
        if (lowBenefitsIndexes.includes(index)) {
          newBenefits[index] = 10
        } else {
          newBenefits[index] = +(
            benefit -
            difference / (array.length - lowBenefitsIndexes.length)
          ).toFixed(2)
        }
      })
    } while (newBenefits.some(benefit => benefit < 10))

    return newBenefits
  }

  render() {
    const { beneficiaries } = this.props
    const isFirstBeneficiary = beneficiaries.length === 0
    const newBenefits = this.calculateNewBenefits(
      beneficiaries.map(b => b.benefit)
    )

    return (
      <div className="container mx-auto mt-5 text-slide-in">
        <div className="grid grid-cols-12">
          <div className="col-span-10 col-start-2 lg:col-span-5 text-center">
            <form onSubmit={this.handleSubmit}>
              <div className="form-input">
                <Input
                  classes="w-full bg-black p-4 focus:bg-white focus:text-black"
                  type="text"
                  name="name"
                  placeholder="Nombre"
                  value={this.state.name}
                  errors={this.state.errors['name']}
                  onChange={this.handleInputChange}
                  onBlur={this.validateInput}
                />
              </div>
              <div className="form-input">
                <Input
                  className="w-full bg-black p-4 focus:bg-white focus:text-black"
                  type="text"
                  name="lastName"
                  placeholder="Apellido"
                  value={this.state.lastName}
                  onChange={this.handleInputChange}
                  errors={this.state.errors['lastName']}
                  onBlur={this.validateInput}
                />
              </div>
              <div className="form-input">
                <DatePicker
                  placeholder={'Fecha nacimiento (dd/MM/aaaa)'}
                  selectedDate={this.state.dob}
                  errors={this.state.errors['dob']}
                  onChange={this.handleDateChange}
                />
              </div>
              <div className="form-input">
                <Select
                  name="relationship"
                  placeholder="Parentesco"
                  items={this.state.relationships}
                  value={this.state.relationship}
                  onChange={this.handleInputChange}
                  errors={this.state.errors['relationship']}
                  onBlur={this.validateInput}
                />
              </div>
              <div className="form-input">
                <Slider
                  title={
                    <span className="flex items-center">
                      Beneficio
                      {isFirstBeneficiary && <RiLock2Line className="ml-2" />}
                    </span>
                  }
                  benefit={this.state.benefit}
                  onChange={this.handleSliderChange}
                  max={100 - 10 * beneficiaries.length}
                  disabled={isFirstBeneficiary}
                />
              </div>
              {isFirstBeneficiary ? (
                <div className="text-left text-purple text-xs my-4">
                  <p className="px-2 my-0">IMPORTANTE</p>
                  <div className="bg-purple bg-opacity-25 rounded p-2">
                    <p className="my-0">
                      El beneficio del 1er beneficiario que agregues será 100%
                      por defecto.
                    </p>
                    <p className="my-0">
                      Al agregar más beneficiarios podrás ajustar los
                      porcentajes.
                    </p>
                    <p className="my-0">
                      Recuerda que puedes agregar un máximo de 3 beneficiarios.
                    </p>
                  </div>
                </div>
              ) : (
                <div className="my-4 text-left">
                  <p className="text-center text-sm m-0">
                    Mis otros beneficiarios
                  </p>
                  {beneficiaries.map((beneficiary, index) => (
                    <div
                      key={beneficiary.uid}
                      className="bg-gray px-2 py-1 mt-2"
                    >
                      <div className="flex justify-between items-center">
                        <div>
                          <p className="m-0">{beneficiary.name}</p>
                          <p className="m-0 text-xs">
                            {beneficiary.relationship}
                          </p>
                        </div>
                        <div className="m-0">{newBenefits[index]}%</div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              <button
                className="default-button w-full py-3 px-8 mb-8 mt-2"
                type="submit"
              >
                {this.props.beneficiary
                  ? 'Editar beneficiario'
                  : 'Agregar beneficiario'}
              </button>
            </form>
          </div>
        </div>
      </div>
    )
  }
}
