/**
 * Calculate user's age based on DOB
 * @param {Object} request
 * @param {Object || Date} dob - User's date of birth
 */
export const calculateAge = ({ dob, isFirebaseDate = false }) => {
    const currDate = new Date()
    const userDob = isFirebaseDate ? new Date(dob.seconds * 1000) : dob
    const diff = currDate - userDob
    return Math.floor(diff / 31557600000)
}