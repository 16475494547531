import React from 'react'

const UserProfileData = ({ profile }) => {
  return (
    <>
      <div className="grid grid-cols-12 gap-2 mb-10">
        <div className='col-span-10'>
          <p className='mb-4 text-xl bg-gray rounded p-1 font-bold opacity-75'>Datos personales</p>
        </div>
        <div className='col-start-1 col-span-4'>
          <p className='mb-3 text-gray-600 text-xs'>Nombre</p>
          <p className='mb-3 text-xl'>{profile.name}</p>
          <p className='mb-3 text-gray-600 text-xs'>Apellido</p>
          <p className='mb-3 text-xl'>{profile.lastName}</p>
        </div>
        <div className='col-span-6'>
          {profile.residence && (
            <>
              <p className='mb-3 text-gray-600 text-xs'>País de residencia</p>
              <p className='mb-3 text-xl'>{profile.residence}</p>
            </>
          )}
          {profile.city && (
            <>
              <p className='mb-3 text-gray-600 text-xs'>Ciudad</p>
              <p className='mb-3 text-xl'>{profile.city}</p>
            </>
          )}
          {profile.address && (
            <>
              <p className='mb-3 text-gray-600 text-xs'>Dirección</p>
              <p className='mb-0 text-xl'>{profile.address}</p>
            </>
          )}
        </div>
        <div className="col-span-12">
          <p className='mb-3 text-gray-600 text-xs'>Correo Electrónico</p>
          <p className='mb-0 text-xl'>{profile.email}</p>
        </div>
      </div>
    </>
  )
}

export default UserProfileData