import React from 'react'

// Components
import FailureIcon from '../../images/failure-icon'

// Libs
import classnames from 'classnames'

// Styles
import modalStyles from '../../../components/modals/modal.module.css'

const BeneficiaryDeletionErrorModal = ({ onClose, onConfirm }) => (
  <>
    <div
      className={classnames(
        modalStyles.modalContainer,
        modalStyles.show,
        'p-8'
      )}
    >
      <div className="grid grid-cols-12 modal-body">
        <div className="col-span-12 w-1/4 mx-auto p-3 mt-1 mb-2">
          <FailureIcon />
        </div>
        <div className="col-span-12 mx-auto">
          <p>
            No puedes eliminar el único beneficiario que tienes.
            <br />
            Debes agregar otro beneficiario primero.
          </p>
        </div>
        <div className="col-span-12 flex-col flex justify-around">
          <button
            className="outlined-default-button py-3 px-6 rounded w-full max-w-xs mx-auto"
            onClick={onClose}
          >
            Hecho
          </button>
          <button
            className="default-button mt-4 py-3 px-6 rounded w-full max-w-xs mx-auto"
            onClick={onConfirm}
          >
            Agregar Beneficiario
          </button>
        </div>
      </div>
    </div>
    <div className={classnames(modalStyles.modalBg, modalStyles.show)} />
  </>
)

export default BeneficiaryDeletionErrorModal
