export default {
  name: {
    presence: { allowEmpty: false, message: 'Campo requerido.' },
  },
  lastName: {
    presence: { allowEmpty: false, message: 'Campo requerido.' },
  },
  dob: {
    presence: { allowEmpty: false, message: 'Campo requerido.' },
  },
  relationship: {
    presence: { allowEmpty: false, message: 'Campo requerido.' },
  },
  benefit: {
    presence: { allowEmpty: false, message: 'Campo requerido.' },
  },
}
