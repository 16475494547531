import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"
import { checkUser } from "../../services/auth"
/**
 * Private route protector 
 */
const PrivateRoute = ({ component: Component, location, ...rest }) => {
    const [uid, getUser] = useState(null)
    const [isCancelled, setCancel] = useState(false)
    
    useEffect(() => {
      const checkUserStatus = async () => {
        if(!isCancelled) {
          const uid = await checkUser()
          getUser(uid)
        }
      }
      checkUserStatus()
      return () => {
        setCancel(true)
      }
  }, [isCancelled])

    if (!uid && uid !== null && location.pathname !== `/auth/login`) {
        navigate("/auth/login")
        return null
    }
    return (<Component userId={uid} location={location} {...rest} />)
}

export default PrivateRoute